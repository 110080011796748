/**
 * 所有表单验证规则统一处理
 * */ 

const ruler = {
  provinceName: [
    { required: true, message: '请选择地区' }
  ],
  userName: [
    { required: true, message: '请选择名字' }
  ],
  userPhone: [
    { required: true, message: '请输入手机号' },
    { pattern: /(^1\d{10}$)|(^09\d{8}$)/, message: '请输入正确的手机号码' }
  ]
}

export default ruler
