<template>
  <el-drawer
    title="客户跟进记录表"
    :visible.sync="drawer"
    :before-close="beforeClose"
    size="100%"
    class="detail-material-container">
    <div class="form-container" style="height: calc(100% - 85px)">
      <el-form :model="form" :rules="rules" label-width="150px" v-for="(form, index) in dataList" :key="index">
        <div class="newest-follow-status">
          <p class="title">{{ index === 0 ? '最新跟进情况' : '第' + (dataList.length - index) + '次跟进情况'}} </p>
          <div class="c-status">
            <span class="label">客户状态</span>
            <el-input size="medium" :value="cStatus(form.dealerClientPlan.customerStatus)" :disabled="true"></el-input>
          </div>
          <!-- 客户跟踪进度 -->
          <el-card class="box-card" style="width:500px;">
            <div class="header">
              <span>客户跟踪进度</span>
            </div>
            <el-form-item prop="dealerClientPlan.pursuitTime" label="首次跟进时间">
              <el-input size="medium" v-model="form.dealerClientPlan.pursuitTime" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.pursuitTime')" />
            </el-form-item>
            <el-form-item prop="dealerClientPlan.interviewTime" label="面试通过时间">
              <el-input size="medium" v-model="form.dealerClientPlan.interviewTime" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.interviewTime')"/>
            </el-form-item>
            <el-form-item prop="dealerClientPlan.recentlyTrailTime" label="最近跟进时间">
              <el-input size="medium" v-model="form.dealerClientPlan.recentlyTrailTime" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.recentlyTrailTime')"/>
            </el-form-item>
            <el-form-item prop="dealerClientPlan.followUpWay" label="跟进方式">
              <el-select size="medium" v-model="form.dealerClientPlan.followUpWay" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.followUpWay')"></el-select>
            </el-form-item>
            <el-form-item prop="dealerClientPlan.feedback" label="最近拜访结果反馈">
              <el-input size="medium" :title="form.dealerClientPlan.feedback" v-model="form.dealerClientPlan.feedback" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.feedback')"/>
            </el-form-item>
            <el-form-item prop="dealerClientPlan.otherShop" label="转化途径">
              <el-input size="medium" :title="form.dealerClientPlan.otherShop" v-model="form.dealerClientPlan.otherShop" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.otherShop')"/>
            </el-form-item>
            <el-form-item prop="dealerClientPlan.passCase" label="面试通过待走流程情况" class="process-status"><!-- 0：有位置资料全、6：已走流程 -->
              <el-select size="medium" v-model="form.dealerClientPlan.passCase" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.passCase')"></el-select>
            </el-form-item>
            <el-form-item
              prop="dealerClientPlan.brandName"
              label="签约系列"
              :rules="form.dealerClientPlan.interviewTime ? rules.signedSeries_required : rules.signedSeries_not_required">
              <el-select size="medium" :title="form.dealerClientPlan.brandName" v-model="form.dealerClientPlan.brandName" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.brandName')"></el-select>
            </el-form-item>
            <el-form-item
              prop="dealerClientPlan.formNumbers"
              label="开店流程单号"
              :rules="form.dealerClientPlan.passCase === '已走流程' ? rules.setStoreProcessNum_required : rules.setStoreProcessNum_not_required">
              <el-input size="medium" v-model="form.dealerClientPlan.formNumbers" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.formNumbers')"></el-input>
            </el-form-item>
            <el-form-item
              prop="dealerClientPlan.planJoinTime"
              label="计划提交加盟流程时间"
              :rules="form.dealerClientPlan.passCase === '有位置资料齐全' ? rules.planJoinTime_required: rules.planJoinTime_not_required">
              <el-input size="medium" v-model="form.dealerClientPlan.planJoinTime" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.planJoinTime')"></el-input>
            </el-form-item>
            <el-form-item prop="dealerClientPlan.remark" label="备注">
              <el-input size="medium" type="textarea" v-model="form.dealerClientPlan.remark" style="display: inline-block" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientPlan.remark')"></el-input>
            </el-form-item>
            <!-- 客户基础档案 -->
            <div class="header">
              <span>客户基础档案</span>
            </div>
            <el-form-item prop="dealerSort" label="新商/老商/二级分销">
              <el-select size="medium" v-model="form.dealerSort" :disabled="true" :class="isDiffColor(index, index+1, 'dealerSort')"></el-select>
            </el-form-item>
            <el-form-item prop="openType" label="开店类型">
              <el-select size="medium" v-model="form.openType" :disabled="true" :class="isDiffColor(index, index+1, 'openType')"></el-select>
            </el-form-item>
            <el-form-item prop="customerName" label="客户姓名">
              <el-input size="medium" v-model="form.customerName" :disabled="true" :class="isDiffColor(index, index+1, 'customerName')"/>
            </el-form-item>
            <el-form-item prop="customerPhone" label="客户电话">
              <el-input size="medium" v-model="form.customerPhone" :disabled="true" :class="isDiffColor(index, index+1, 'customerPhone')"></el-input>
            </el-form-item>
            <el-form-item prop="otherBrand" label="经营品牌">
              <el-input size="medium" :title="form.otherBrand" v-model="form.otherBrand" :disabled="true" :class="isDiffColor(index, index+1, 'otherBrand')"></el-input>
            </el-form-item>
            <el-form-item prop="industryInvolved" label="所属行业">
              <el-select size="medium" v-model="form.industryInvolved" :disabled="true" :class="isDiffColor(index, index+1, 'industryInvolved')"></el-select>
            </el-form-item>
            <el-form-item
              prop="manageDuration"
              label="从事经营时间(年)"
              :rules="form.dealerClientPlan.interviewTime ? rules.manageTime_required : rules.manageTime_not_required">
              <el-input type="number" size="medium" v-model="form.manageDuration" :disabled="true" :class="isDiffColor(index, index+1, 'manageDuration')"></el-input>
            </el-form-item>
            <el-form-item
              class="form-item-label-20"
              prop="annualSales"
              label="经营情况(年销售额)(万)"
              :rules="form.dealerClientPlan.interviewTime ? rules.annualSales_required : rules.annualSales_not_required">
              <el-input type="number" size="medium" v-model="form.annualSales" :disabled="true" :class="isDiffColor(index, index+1, 'annualSales')"></el-input>
            </el-form-item>
            <el-form-item
              class="form-item-label-20"
              prop="oldShopArea"
              label="门店规模(店数/面积)(m2)"
              :rules="form.dealerClientPlan.interviewTime ? rules.oldShopArea_required : rules.oldShopArea_not_required">
              <el-input type="number" size="medium" v-model="form.oldShopArea" :disabled="true" :class="isDiffColor(index, index+1, 'oldShopArea')"></el-input>
            </el-form-item>
            <el-form-item
              prop="teamSize"
              label="团队规模(人)"
              :rules="form.dealerClientPlan.interviewTime ? rules.teamSize_required : rules.teamSize_not_required">
              <el-input size="medium" v-model="form.teamSize" :disabled="true" :class="isDiffColor(index, index+1, 'teamSize')"></el-input>
            </el-form-item>
            <el-form-item
              prop="locationSign"
              label="是否有意向店面"
              :rules="form.dealerClientPlan.interviewTime ? rules.intentionStore_required : rules.intentionStore_not_required">
              <el-select size="medium" v-model="form.locationSign === 0 ? '否' : '是'" :disabled="true" :class="isDiffColor(index, index+1, 'locationSign')"></el-select>
            </el-form-item>
            <el-form-item
              prop="shopType"
              label="门店类型"
              :rules="form.locationSign ? rules.storeType_required : rules.storeType_not_required">
              <el-select size="medium" v-model="form.shopType" :disabled="true" :class="isDiffColor(index, index+1, 'shopType')"></el-select>
            </el-form-item>
            <el-form-item
              prop="shopArea"
              label="面积(m2)"
              :rules="form.locationSign ? rules.shopArea_required : rules.shopArea_not_required">
              <el-input size="medium" v-model="form.shopArea" :disabled="true" :class="isDiffColor(index, index+1, 'acreage')"></el-input>
            </el-form-item>
            <el-form-item
              prop="intentionSeries"
              label="意向加盟系列"
              :rules="form.dealerClientPlan.interviewTime ? rules.intentionSeries_required : rules.intentionSeries_not_required">
              <el-select size="medium" :title="form.intentionSeries" v-model="form.intentionSeries" :disabled="true" :class="isDiffColor(index, index+1, 'intentionSeries')"></el-select>
            </el-form-item>
            <el-form-item
              prop="intentionType"
              label="客户意向分类"
              :rules="form.dealerClientPlan.interviewTime ? rules.intentionType_required : rules.intentionType_not_required">
              <el-select size="medium" v-model="form.intentionType" :disabled="true" :class="isDiffColor(index, index+1, 'intentionType')"></el-select>
            </el-form-item>
            <!-- 意向城市情况 -->
            <div class="header">
              <span>意向城市情况</span>
            </div>
            <el-form-item prop="dealerClientCity.serviceCenter" label="服务中心">
              <el-input size="medium" v-model="form.dealerClientCity.serviceCenter" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.serviceCenter')"/>
            </el-form-item>
            <el-form-item prop="dealerClientCity.province" label="省份">
              <el-select size="medium" v-model="form.dealerClientCity.province"  :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.province')"></el-select>
            </el-form-item>
            <el-form-item prop="dealerClientCity.city" label="地级市">
              <el-select size="medium" v-model="form.dealerClientCity.city" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.city')"></el-select>
            </el-form-item>
            <el-form-item prop="dealerClientCity.area" label="区县/县级市">
              <el-select size="medium" v-model="form.dealerClientCity.area" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.area')"></el-select>
            </el-form-item>
            <el-form-item prop="dealerClientCity.town" label="镇">
              <el-select size="medium" v-model="form.dealerClientCity.town" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.town')"></el-select>
            </el-form-item>
            <el-form-item
              prop="dealerClientCity.urbanEconomy"
              label="空白城市分级"
              :rules="form.openType === '空白' ? rules.urbanEconomy_required : rules.urbanEconomy_not_required">
              <el-select size="medium" v-model="form.dealerClientCity.urbanEconomy" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.urbanEconomy')"></el-select>
            </el-form-item>
            <el-form-item
              prop="dealerClientCity.levelName"
              label="城市级别(已开城市)"
              :rules="form.openType === '转接' || form.openType === '加密' ? rules.levelName_required : rules.levelName_not_required">
              <el-select size="medium" v-model="form.dealerClientCity.levelName" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.levelName')"></el-select>
            </el-form-item>
            <el-form-item prop="dealerClientCity.scheduledTime" label="预计开发时间">
              <el-input size="medium" v-model="form.dealerClientCity.scheduledTime" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.scheduledTime')"></el-input>
            </el-form-item>
            <el-form-item prop="dealerClientCity.regionUserName" label="负责人">
              <el-input size="medium" v-model="form.dealerClientCity.regionUserName" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.regionUserName')"></el-input>
            </el-form-item>
            <el-form-item prop="dealerClientCity.followUpName" label="客户跟进人">
              <el-select size="medium" v-model="form.dealerClientCity.followUpName" :disabled="true" :class="isDiffColor(index, index+1, 'dealerClientCity.followUpName')"></el-select>
            </el-form-item>
          </el-card>
        </div>
      </el-form>
    </div>
    <div class="demo-drawer__footer">
      <el-button style="width: 180px" @click="cancelForm">取 消</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { record } from '../../../../api/attractPlatform/materialManage'
export default {
  props: [
    'addOrEdit'
  ],
  data: function() {
    return {
      drawer: false,
      rules: {
        'dealerClientPlan.pursuitTime': [
          { required: true, message: '请选择首次跟进时间', trigger: 'change'}
        ],
        dealerSort: [
          { required: true, message: '请选择新商/老商/二级分销', trigger: 'change'}
        ],
        openType: [
          { required: true, message: '请选择开店类型', trigger: 'change'}
        ],
        customerName: [
          { required: true, message: '请填写客户姓名', trigger: 'blur'}
        ],
        customerPhone: [
          { required: true, message: '请填写客户电话', trigger: 'blur'}
        ],
        otherBrand: [
          { required: true, message: '请填写经营品牌', trigger: 'blur'}
        ],
        industryInvolved: [
          { required: true, message: '请选择所属行业', trigger: 'change'}
        ],
        'dealerClientCity.serviceCenter': [
          { required: true, message: '请选择服务中心', trigger: 'change'}
        ],
        'dealerClientCity.province': [
          { required: true, message: '请选择省份', trigger: 'change'}
        ],
        'dealerClientCity.city': [
          { required: true, message: '请选择地级市', trigger: 'change'}
        ],
        'dealerClientCity.area': [
          { required: true, message: '请选择区县/县级市', trigger: 'change'}
        ],
        'dealerClientCity.scheduledTime': [
          { required: true, message: '请选择预计开发时间', trigger: 'change'}
        ],
        'dealerClientCity.regionUserName': [
          { required: true, message: '请选择负责人', trigger: 'change'}
        ],
        'dealerClientCity.followUpName': [
          { required: true, message: '请选择客户跟进人', trigger: 'change'}
        ],

        signedSeries_required: [
          { required: true, message: '请选择签约系列', trigger: 'change'}
        ],
        signedSeries_not_required: [
          { required: false }
        ],
        setStoreProcessNum_required: [
          { required: true, message: '请填写开店流程单号', trigger: 'blur' }
        ],
        setStoreProcessNum_not_required: [
          { required: false }
        ],
        planJoinTime_required: [
          { required: true, message: '请选择计划提交加盟时间', trigger: 'change' }
        ],
        planJoinTime_not_required: [
          { required: false }
        ],
        manageTime_required: [
          { required: true, message: '请填写从事经营时间(年)', trigger: 'blur' }
        ],
        manageTime_not_required: [
          { required: false }
        ],
        annualSales_required: [
          { required: true, message: '请填写经营情况(年销售额)(万)', trigger: 'blur' }
        ],
        annualSales_not_required: [
          { required: false }
        ],
        oldShopArea_not_required_required: [
          { required: true, message: '请填写门店规模(店数/面积)(m2)', trigger: 'blur' }
        ],
        oldShopArea_not_required_not_required: [
          { required: false }
        ],
        teamSize_required: [
          { required: true, message: '请填写团队规模(人)', trigger: 'blur' }
        ],
        teamSize_not_required: [
          { required: false }
        ],
        intentionStore_required: [
          { required: true, message: '请选择是否有意向店面', trigger: 'change' }
        ],
        intentionStore_not_required: [
          { required: false }
        ],
        storeType_required: [
          { required: true, message: '请选择门店类型', trigger: 'change' }
        ],
        storeType_not_required: [
          { required: false }
        ],
        shopArea_required: [
          { required: true, message: '请填写面积(m2)', trigger: 'blur' }
        ],
        shopArea_not_required: [
          { required: false }
        ],
        intentionSeries_required: [
          { required: true, message: '请选择意向加盟系列', trigger: 'change' }
        ],
        intentionSeries_not_required: [
          { required: false }
        ],
        intentionType_required: [
          { required: true, message: '请选择客户意向分类', trigger: 'change' }
        ],
        intentionType_not_required: [
          { required: false }
        ],
        urbanEconomy_required: [
          { required: true, message: '请选择空白城市分级', trigger: 'change' }
        ],
        urbanEconomy_not_required: [
          { required: false }
        ],
        levelName_required: [
          { required: true, message: '请选择城市级别(已开城市)', trigger: 'change' }
        ],
        levelName_not_required: [
          { required: false }
        ]
      },
      dataList: []
    }
  },
  methods: {
    beforeClose(done) {
      document.documentElement.style.overflow = '';
      done();
    },
    /* 客户状态 */
    cStatus(customerStatus) {
      if (customerStatus === 0) {
        return '跟进中';
      } else if (customerStatus === 1) {
        return '已加盟';
      } else if (customerStatus === 2) {
        return '已战败';
      } else {
        return '';
      }
    },
    async getData(customerPhone) {
      const param = { customerPhone }
      const res = await record(param);
      if (!res.code) {
        this.dataList = res.data;
      }
    },
    cancelForm() {
      document.documentElement.style.overflow = '';
      this.drawer = false;
    },
    isDiffColor(index1, index2, property) {
      if (index2 === this.dataList.length) return '';
        return (eval('this.dataList[' + index1 + '].' + property) !== eval('this.dataList[' + index2 + '].' + property)) ? 'diff-color' : '';
      }
  }
}
</script>

<style lang="scss">
.detail-material-container  {
  .el-drawer__body {
    overflow: auto;
    .form-container {
      display: flex;
      overflow: auto;
      padding: 0 10px 10px;
    }
    .el-form + .el-form {
      margin-left: 30px;
    }
    .title {
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
    }
    .c-status {
      font-size: 14px;
      margin: 0 0 10px 0;
      border: 1px solid #e6ebf5;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      padding: 5px 10px;
      border-radius: 30px;
      .label {
        color: rgba(235, 57, 65);
        font-weight: 600;
        margin-right: 10px;
        width: 150px;
        display: inline-block;
        text-align: right;
      }
    }
    .el-card {
      min-width: 500px;
      display: inline-block;
      .header {
        color: #303133;
        font-weight: bold;
        padding: 0 0 10px 0;
      }
      .el-card__body {
        overflow: auto;
        height: calc(100% - 55px);
      }
      .el-form-item__label {
        font-size: 13px;
      }
      .form-item-label-20 .el-form-item__label {
        line-height: 20px;
      }
    }
  }
  .demo-drawer__footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 14px;
  }
  .diff-color .el-input__inner {
    color: indianred;
  }
}
</style>
