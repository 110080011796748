<template>
<div class="list-container">
  <el-form :model="searchForm" :inline="true">
    <el-form-item label="省份" label-width="100px">
      <el-input v-model="searchForm.province" placeholder="省份"></el-input>
    </el-form-item>
    <el-form-item label="市" label-width="100px">
      <el-input v-model="searchForm.city" placeholder="城市"></el-input>
    </el-form-item>
    <el-form-item label="区" label-width="100px">
      <el-input v-model="searchForm.area" placeholder="区"></el-input>
    </el-form-item>
    <el-form-item label="创建月份" label-width="100px">
      <el-date-picker
        v-model="searchForm.month"
        type="month"
        placeholder="选择创建月份">
      </el-date-picker>
    </el-form-item>
    <el-form-item label="客户意向分类" label-width="100px">
      <el-select v-model="searchForm.intentionType" placeholder="请选择客户意向分类" clearable >
        <el-option v-for="item in intentionTypeList" :key="item.code" :label="item.content" :value="item.content"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="面试通过待走流程情况" label-width="100px" class="process-status">
      <el-select v-model="searchForm.passCase" placeholder="请选择面试通过待走流程情况" clearable >
        <el-option v-for="item in passCaseList" :key="item.code" :label="item.content" :value="item.content"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="客户状态" label-width="100px">
      <el-select v-model="searchForm.customerStatus" placeholder="请选择客户状态" clearable >
        <el-option v-for="item in customerStatusList" :key="item.code" :label="item.content" :value="item.code"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="客户姓名" label-width="100px">
      <el-input v-model="searchForm.customerName" placeholder="客户姓名"></el-input>
    </el-form-item>
    <el-form-item label="手机号" label-width="100px">
      <el-input v-model="searchForm.customerPhone" placeholder="手机号"></el-input>
    </el-form-item>
    <el-form-item label="服务中心" label-width="100px">
      <el-select v-model="searchForm.serviceCenter" placeholder="请选择服务中心" clearable >
        <el-option v-for="item in serviceCenterList" :key="item.code" :label="item.content" :value="item.content"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="负责人" label-width="100px">
      <el-input v-model="searchForm.userName" placeholder="负责人"></el-input>
    </el-form-item>
    <el-form-item label="面试通过时间" label-width="100px" class="pass-time">
      <el-date-picker
        v-model="searchForm.interviewTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd">
      </el-date-picker>
    </el-form-item>
    <el-form-item label="最近跟进更新时间" label-width="100px" class="update-time">
      <el-date-picker
        v-model="searchForm.recentlyTrailTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd">
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" icon="el-icon-search" @click="getDataList(1)">搜 索</el-button>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" icon="el-icon-plus" @click="add" >新 增</el-button>
      <el-dropdown split-button type="primary" trigger="click" @click="export_excel()" style="margin-left: 10px" @command="export_excel">
        excel导出
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">导出最新记录</el-dropdown-item>
          <el-dropdown-item command="1">导出全部记录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-form-item>
  </el-form>
  <el-table ref="table" border :data="tableData">
    <el-table-column type="selection" width="55"></el-table-column>
    <el-table-column prop="month" label="创建月份"></el-table-column>
    <el-table-column prop="serviceCenter" label="服务中心"></el-table-column>
    <el-table-column prop="regionUserName" label="负责人"></el-table-column>
    <el-table-column prop="province" label="省份"></el-table-column>
    <el-table-column prop="city" label="地级市"></el-table-column>
    <el-table-column prop="area" label="县/区"></el-table-column>
    <el-table-column prop="dealerSort" label="新商/老商/二级分销"></el-table-column>
    <el-table-column prop="shopType" label="开店类型"></el-table-column>
    <el-table-column prop="customerName" label="客户姓名"></el-table-column>
    <el-table-column prop="customerPhone" label="客户电话"></el-table-column>
    <el-table-column prop="recentlyTrailTime" label="最近跟进时间"></el-table-column>
    <el-table-column prop="interviewTime" label="面试通过时间"></el-table-column>
    <el-table-column prop="passCase" label="面试通过待走流程情况"></el-table-column>
    <el-table-column prop="formNumbers" label="开店流程单号"></el-table-column>
    <el-table-column label="操作"  width="300">
      <template slot-scope="scope">
        <el-button type="text" class="text-btn" v-if="scope.row.customerStatus === 0" @click="edit(scope.row.id, '1')">进度更新</el-button>
        <el-button type="text" class="text-btn" v-if="scope.row.customerStatus === 0" @click="edit(scope.row.id, '2')">客户关闭</el-button>
        <el-button type="text" class="text-btn" @click="detail(scope.row.customerPhone)">客户跟踪记录</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    class="paginatinBar"
    background
    layout="total,prev,pager,next,sizes,jumper"
    :total="total"
    :page-sizes="[5, 10, 30, 50]"
    :page-size="pageSize"
    :current-page="currPage"
    @current-change="currentChange"
    @size-change="handleSizeChange">
  </el-pagination>
  <!-- 新增 / 编辑 -->
  <EditMaterial ref="editMaterial" :addOrEdit="addOrEdit" @getDataList="getDataList"></EditMaterial>
  <!-- 客户跟进记录表 -->
  <DetailMaterial ref="detailMaterial"></DetailMaterial>
</div>
</template>

<script>
import EditMaterial from "./components/editMaterial";
import DetailMaterial from "./components/detailMaterial";
import RequiredModuleFormItemSelect from "@/components/CustomFormItem/requiredModuleFormItemSelect";
import { getDataList, getSelectList, exportExcel } from '../../../api/attractPlatform/materialManage';
export default {
  components: { DetailMaterial, EditMaterial, RequiredModuleFormItemSelect },
  data() {
    return {
      province_list: [],
      city_list: [],
      area_list: [],
      intentionTypeList: [],  // 意向分类下拉
      passCaseList: [],  // 面试通过待走流程下拉
      customerStatusList: [{
        code: '0',
        content: '跟进中'
      }, {
        code: '1',
        content: '已加盟'
      }, {
        code: '2',
        content: '已战败'
      }],  // 客户状态下拉
      serviceCenterList: [],  // 服务中心下拉
      searchForm: {
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        area: '',
        areaCode: '',
        month: '',
        intentionType: '',
        passCase: '',
        customerStatus: '',
        recentlyTrailTime: '',
        customerName: '',
        customerPhone: '',
        interviewTime: '',
        serviceCenter: '',
        userName: '',
        regionUserName: ''
      },
      tableData: [],
      pageSize: 10,
      currPage: 1,
      total: 0,

      addOrEdit: '0'  // 0: 新增 1：编辑 2：关闭客户
    }
  },
  mounted() {
    const cloud_userInfo = JSON.parse(localStorage.getItem('cloud_userInfo'));
    this.searchForm.regionUserName = cloud_userInfo.realName;
    this.getDataList();
    this.getSelectList();
  },
  methods: {
    async getSelectList() {
      // 客户意向分类下拉
      let param = {
        module: 'client',
        type: 'intention_type'
      }
      let res = await getSelectList(param);
      if (!res.code) {
        this.intentionTypeList = res.data
      }
      // 面试通过待走流程下拉
      param = {
        module: 'client',
        type: 'pass_case'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.passCaseList = res.data
      }
      // 服务中心下拉
      param = {
        module: 'client',
        type: 'service_center'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.serviceCenterList = res.data
      }
    },
    getSearchParam(param) {
      if (!param) param = {};
      for (let item in this.searchForm) {
        if (!this.searchForm[item] || item === 'provinceCode' || item === 'cityCode' || item === 'areaCode') {
          continue;
        }
        if (item === 'recentlyTrailTime') {
          param.startTrailTime = this.searchForm[item][0];
          param.endTrailTime = this.searchForm[item][1];
        } else if (item === 'interviewTime') {
          param.startInterviewTime = this.searchForm[item][0];
          param.endInterviewTime = this.searchForm[item][1];
        } else {
          param[item] = this.searchForm[item];
        }
      }
      return param;
    },
    async getDataList(isSearch) {
      if (isSearch) this.currPage = 1;
      let param = {
        currPage: this.currPage,
        pageSize: this.pageSize
      }
      param = this.getSearchParam(param);
      const res = await getDataList(param);
      if (!res.code) {
        this.tableData = res.data;
        this.total = res.totalCount;
      }
    },
    currentChange(page) {
      this.currPage = page;
      this.getDataList();
    },
    handleSizeChange(page) {
      this.pageSize = page;
      this.getDataList(1);
    },
    add() {
      document.documentElement.style.overflow = 'hidden';
      this.addOrEdit = '0';
      this.$refs.editMaterial.drawer = true;
    },
    edit(id, type) {  // 更新、关闭
      document.documentElement.style.overflow = 'hidden';
      this.addOrEdit = type;
      this.$refs.editMaterial.drawer = true;
      this.$refs.editMaterial.getData(id);
    },
    detail(customerPhone) {
      document.documentElement.style.overflow = 'hidden';
      this.$refs.detailMaterial.drawer = true;
      this.$refs.detailMaterial.getData(customerPhone);
    },
    async export_excel(val) {
      const param = this.getSearchParam();
      if (val === '1') {  // 全部历史数据
        param.entire = '1';
      }
      const res = await exportExcel(param);
      this.$message.success('导出成功');
    }
  }
}
</script>

<style lang='scss'>
@import '@/styles/colors';
@import '@/styles/mixin';

.list-container {
  .el-form .el-input {
    width: 200px !important;
  }

  .process-status .el-form-item__label,
  .update-time .el-form-item__label {
    line-height: 20px;
  }

  .text-btn {
    color: $primary-color;
  }

  .paginatinBar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);
  }
  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
</style>
