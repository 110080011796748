<template>
  <div class="box">
    <div class="body">
      <!-- <el-card class="cardSelect">
        <div slot="header" class="cardHeader">
          <span>筛选查询</span>
        </div> -->
      <el-form :model="information" :inline="true">
        <el-form-item label="省份：">
          <el-input v-model="information.provinceName" placeholder="输入省份" clearable />
        </el-form-item>
        <el-form-item label="责任人：">
          <el-input v-model="information.userName" placeholder="输入责任人" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="check">查询</el-button>
          <el-button icon="el-icon-refresh-right" @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- </el-card> -->
      <!-- <el-card class="cardSelect"> -->
      <div slot="header" class="cardHeader">
        <!-- <span>地区责任人列表</span> -->
        <div class="btnBox">
          <el-button type="primary" icon="el-icon-plus" @click="dialogVisible=true, change=0">添加</el-button>
          <el-button type="danger" icon="el-icon-delete" @click="del">删除</el-button>
          <el-button type="warning" icon="el-icon-edit" @click="setChange">修改</el-button>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableList"
        tooltip-effect="dark"
        style="width:100%"
        border
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D', textAlign: 'center' }"
        :cell-style="{ padding: '12px 0', textAlign: 'center' }"
        @selection-change="handleSelectionChange"
        @row-click="clickList"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column prop="provinceName" label="省份" />
        <el-table-column prop="userName" label="责任人" />
        <el-table-column prop="userPhone" label="责任人电话" />
        <el-table-column prop="brandName" label="负责大品牌" />
      </el-table>
      <div class="footer">
        <div class="paginationBar">
          <el-pagination
            background
            layout="total,prev,pager,next,sizes,jumper"
            :page-sizes="[10, 30, 50]"
            :total="totalCount"
            :page-size="pageSize"
            :current-page="currPage"
            @current-change="currentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
      <!-- </el-card> -->
    </div>
    <!-- 弹框 -->
    <div class="dialog">
      <el-dialog
        :title="change===0? '新增': '修改'"
        :visible.sync="dialogVisible"
        width="500px"
        :before-close="handleClose"
      >
        <el-form ref="form" :model="form" :rules="ruler" label-width="100px">
          <el-form-item label="管辖地区" prop="provinceName">
            <div>
              <el-input v-show="form.provinceName" v-model="form.provinceName" :disabled="true" />
              <search
                class="searchBtn"
                :index="0"
                :search-content="formItem[0].searchContent"
                :head="'选择地区'"
                :title="'管辖地区'"
                :change="change"
                @readData="readData($event)"
                @searchFn="searchFn($event)"
              />
            </div>
          </el-form-item>
          <el-form-item label="地区负责人" prop="userName">
            <div>
              <el-input v-show="form.userName" v-model="form.userName" :disabled="true" />
              <search
                :index="1"
                :search-content="formItem[1].searchContent"
                :head="'选择责任人'"
                :title="'地区负责人'"
                :change="change"
                @loadIndex="loadIndex"
                @readData="readData($event)"
                @searchFn="searchFn($event)"
                @onComfirm="onComfirm"
              />
            </div>
          </el-form-item>
          <el-form-item label="电话号码" prop="userPhone">
            <el-input v-model="form.userPhone" style="width:199px" />
          </el-form-item>
          <required-form-item-select
            label="负责大品牌"
            prop="brandId"
            labelKey="name"
            valueKey="id"
            :getDataList="getBrandList"
            :getNextList="getBrandList"
            v-model="form.brandId"
            :selectProps="{
              filterable: true,
              remote: true,
              clearable: true
            }"
            @changeSelectedItem="(row) => form.brandName = row && row.name || ''"
          >
          </required-form-item-select>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="canel">取 消</el-button>
          <el-button type="primary" @click="postForm('form')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { gettableList, getRegionUser, getUserName, postUser, postDel, postChangeProvice, brandSeries } from '../../../api/attractPlatform/regionPersonal'
import search from './search'
import ruler from './ruler.js'
import RequiredFormItemSelect from "../../../components/CustomFormItem/requiredFormItemSelect";
export default {
  components: {
    RequiredFormItemSelect,
    search
  },
  data() {
    return {
      dialogVisible: false, // 弹框开关
      multipleSelection: [],
      information: { // 筛选查询内容
        provinceName: '',
        userName: ''
      },
      tableList: [],
      totalCount: 50, // 总条数
      currPage: 1, // 当前页
      pageSize: 10, // 每页条数,
      formItem: [
        { title: '管辖地区', text: 'provinceName', searchContent: [], currPage: 1, pageSize: 10, totalPage: '', head: '选择地区' },
        { title: '地区负责人', text: 'userName', searchContent: [], currPage: 1, pageSize: 10, totalPage: '', head: '选择责任人' }
      ],
      form: { // 表单提交
        provinceName: '', // 管辖地区
        userName: '', // 地区负责人
        userPhone: '', // 电话号码
        provinceCode: '', // 地区编号
        userId: '', // 用户id
        id: '',
        brandId: '',  // 负责大品牌
        brandName: ''
      },
      popoverKey: false, // 二层弹框开关
      searchIndex: '', // 显示第几输入框
      searchTitle: '', // 搜索框内容,
      checkedCities: '', // 选择内容
      searchContent: [], // 二层弹框列表
      ruler: ruler,
      change: 0 // 显示
    }
  },
  computed: {
    setText() {
      return (val) => {
        if (val) {
          return val
        } else {
          return '点击选择'
        }
      }
    }
  },
  mounted() {
    this.tagList()
  },
  methods: {
    // 请求数据
    async tagList() {
      const data = {
        provinceName: this.information.provinceName,
        userName: this.information.userName,
        pageSize: this.pageSize,
        currPage: this.currPage
      }
      const res = await gettableList(data)
      this.tableList = res.data
      this.totalCount = res.totalCount
    },
    // 查询
    check() {
      this.tagList()
    },
    // 修改
    async setChange() {
      if (this.multipleSelection.length === 1) {
        this.dialogVisible = true
        this.change = 1
        this.form = { ...this.multipleSelection[0] }
      } else {
        this.$message.error('请选择一条数据')
      }
    },
    // 重置
    refresh() {
      Object.assign(this.$data, this.$options.data())
      this.tagList()
    },
    // 勾选数据
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log('multipleSelection', val, this.multipleSelection)
    },
    // 点击所在行打√功能
    clickList(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    handleClose(done) {
      done()
    },
    // 搜索
    searchFn(arg) {
      if (arg.index === 0) {
        this.searchTitle = arg.searchTitle
        this.getRegionUserList()
      }
      if (arg.index === 1) {
        this.formItem[1].currPage = 1
        this.searchTitle = arg.searchTitle
        this.getUserNameList()
      }
    },
    // 管辖地区
    async getRegionUserList() {
      const obj = {
        parentId: 0,
        currPage: this.formItem[0].currPage,
        pageSize: this.formItem[0].pageSize,
        districtName: this.searchTitle
      }
      const res = await getRegionUser(obj)
      this.formItem[0].searchContent = res.data
      this.formItem[0].totalPage = res.totalPage
    },
    // 地区负责人
    async getUserNameList() {
      const obj = {
        loginType: '10',
        sourceNum: '20',
        currPage: this.formItem[1].currPage,
        pageSize: this.formItem[1].pageSize
      }// 检测是否数字
      if (/^\d+$/.test(this.searchTitle)) {
        obj.code = this.searchTitle
      } else {
        obj.realName = this.searchTitle
      }
      const res = await getUserName(obj)
      this.formItem[1].currPage === 1 ? this.formItem[1].searchContent = res.data : this.formItem[1].searchContent.push(...res.data)
      this.formItem[1].totalPage = res.totalPage
    },
    // 无限滚动请求
    async loadIndex() {
      console.log('111f')
      if (this.formItem[1].currPage >= this.formItem[1].totalPage) {
        return
      }
      this.formItem[1].currPage += 1
      await this.getUserNameList()
    },
    // 二层弹框写入数据
    readData(arg) {
      this.form.provinceName = arg
    },
    onComfirm(checkItem) {
      this.form.userName = checkItem.real_name || ''
      this.form.userPhone = checkItem.phone || ''
    },
    /* 负责大品牌下拉 */
    async getBrandList({ page, searchText }) {
      const param = {
        pageSize: 10,
        currPage: page,
        brandLevel: '0',
        name: searchText
      }
      const { code, data } = await brandSeries(param)
      if (!code) return data
    },
    // 弹框确定提交表单
    async postForm(formName) {
      if (this.form.provinceCode === '') {
        this.form.provinceCode = this.formItem[0].searchContent.filter(item => item.districtName === this.form.provinceName)[0].id
      }
      if (this.form.userId === '' || this.change === 1) {
        this.form.userId = this.formItem[1].searchContent.filter(item => item.real_name === this.form.userName).length > 0 ?
          this.formItem[1].searchContent.filter(item => item.real_name === this.form.userName)[0].id : this.form.userId
      }
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const res = this.change === 0 ? await postUser(this.form) : await postChangeProvice(this.form)
          const msg = ['新增成功', '修改成功']
          await this.tagList()
          this.$message({
            type: 'success',
            message: msg[this.change]
          })
          this.dialogVisible = false
          this.title = 0
          this.change = 0
          this.form = this.$options.data().form
        }
      })
    },
    // 弹框取消
    canel() {
      this.dialogVisible = false
      this.form = this.$options.data().form
    },
    // 删除
    async del() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择表格')
        return
      }
      const arr = this.multipleSelection.map(item => item.id)
      console.log('arr', arr)
      const res = await postDel(arr)
      console.log('res', res)
      this.$message({
        type: 'success',
        message: '删除成功'
      })
      this.tagList()
      this.multipleSelection = []
    },
    // 页面切换
    currentChange(page) {
      console.log('paga当前页', page)
      this.currPage = page
      const data = {}
      this.tagList(data)
    },
    // 页数切换
    handleSizeChange(page) {
      console.log('每页条数', page)
      this.pageSize = page
      this.currPage = 1
      const data = {}
      // data.page = this.currPage
      // data.limit = this.pageSize
      this.tagList(data)
    }
  }

}
</script>

<style scoped lang="scss">
.box{
  // padding: 20px;
  min-width:600px;
  /*elemnt 滚动条*/
  ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .1);
      box-shadow:inset 0 0 6px rgba(0, 0, 0, .3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  }
  // 标题
  .title{
    font-size: 28px;
    font-weight: 500;
    // background:rgba(201, 207, 207, 0.3);
    padding:12px 5px;
    // border-radius:5px;
  }
  .cardHeader{
    display: flex;
    justify-content: space-between;
    .btnBox{
      margin-bottom: 20px;
    }
  }
  // 标签列表
  .body{
    margin-top:20px;
    .cardSelect{
      margin-bottom:40px;
    }
    .tagList{
      .tagHead {
        margin-top:20px;
        height:60px;
        line-height: 60px;
        text-align:right;
        background:rgba(197, 193, 193, 0.1);
      }
    }
    .tagContent{
      .card{
        margin: 0 0 10px 0;
        .cardContent{
          .tagName{
            margin: 5px 10px;
          }
          .el-icon-edit{
            font-size:12px;
            height:16px;
            width:16px;
            line-height:16px;
            margin-left:5px;
            // vertical-align: middle;
            text-align: center;
          }
          .el-icon-edit:hover{
            background:#409eff;
            color:#fff;
            border-radius:50%;
          }
        }
      }
    }
    .footer{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top:20px;
    }
  }
  // .dialog{
  //   &::v-deep .el-form{
  //     width:400px;
  //     .el-input{
  //       width:60%;
  //       margin-right:20px;
  //     }
  //   }
  // }
}
</style>
