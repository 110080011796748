<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :before-close="beforeClose"
    size="100%"
    class="edit-material-container">
    <div class="c-status" v-if="addOrEdit === '2'">
      <label>客户状态</label>
      <el-select size="medium" v-model="form.dealerClientPlan.customerStatus" clearable>
        <el-option v-for="item in customerStatusList" :key="item.code" :value="item.code" :label="item.content"></el-option>
      </el-select>
      <span class="c-status-remark">备注: 客户关闭请更新客户最新信息</span>
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <!-- 客户跟踪进度 -->
      <el-card class="box-card" style="width:500px;height:700px">
        <div slot="header" class="clearfix">
          <span>客户跟踪进度</span>
        </div>
        <el-form-item prop="dealerClientPlan.pursuitTime" label="首次跟进时间">
          <el-date-picker
            v-model="form.dealerClientPlan.pursuitTime"
            type="datetime"
            placeholder="选择首次跟进时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :disabled="addOrEdit !== '0'">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="dealerClientPlan.interviewTime" label="面试通过时间">
          <el-date-picker
            v-model="form.dealerClientPlan.interviewTime"
            type="datetime"
            placeholder="选择面试通过时间"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="dealerClientPlan.recentlyTrailTime" label="最近跟进时间">
          <el-date-picker
            v-model="form.dealerClientPlan.recentlyTrailTime"
            type="datetime"
            placeholder="选择最近跟进时间"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="dealerClientPlan.followUpWay" label="跟进方式">
          <el-select size="medium" v-model="form.dealerClientPlan.followUpWay" clearable>
            <el-option v-for="item in followUpWayList" :key="item.code" :label="item.content" :value="item.content"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="dealerClientPlan.feedback" label="最近拜访结果反馈">
          <el-input size="medium" v-model="form.dealerClientPlan.feedback" placeholder="最多输入100字符"/>
        </el-form-item>
        <el-form-item prop="dealerClientPlan.otherShop" label="转化途径">
          <el-input size="medium" v-model="form.dealerClientPlan.otherShop" placeholder="最多输入50字符"/>
        </el-form-item>
        <el-form-item prop="dealerClientPlan.passCase" label="面试通过待走流程情况" class="process-status"><!-- 0：有位置资料全、6：已走流程 -->
          <el-select size="medium" v-model="form.dealerClientPlan.passCase" clearable>
            <el-option v-for="item in passCaseList" :key="item.code" :label="item.content" :value="item.content"></el-option>
          </el-select>
        </el-form-item>
        <required-form-item-select
          size="medium"
          label="签约系列"
          prop="dealerClientPlan.brandCode"
          labelKey="name"
          valueKey="code"
          getDataListParams="name"
          :rules="form.dealerClientPlan.interviewTime ? rules.brandCode_required : rules.brandCode_not_required"
          :getDataList="getBrandSeriesList"
          :getNextList="getBrandSeriesList"
          v-model="form.dealerClientPlan.brandCode"
          :required="false"
          :selectProps="{
              filterable: true,
              remote: true,
              clearable: true,
              multiple: true
            }"
          @changeSelectedItem="(row) => form.dealerClientPlan.brandName = row && row.map(item => item.name) || ''"
        />
        <el-form-item
          prop="dealerClientPlan.formNumbers"
          label="开店流程单号"
          :rules="form.dealerClientPlan.passCase === '已走流程' ? rules.formNumbers_required : rules.formNumbers_not_required">
          <el-input size="medium" v-model="form.dealerClientPlan.formNumbers"></el-input>
        </el-form-item>
        <el-form-item
          prop="dealerClientPlan.planJoinTime"
          label="计划提交加盟流程时间"
          :rules="form.dealerClientPlan.passCase === '有位置资料齐全' ? rules.planJoinTime_required: rules.planJoinTime_not_required">
          <el-date-picker
            v-model="form.dealerClientPlan.planJoinTime"
            type="datetime"
            placeholder="选择计划提交加盟时间"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="dealerClientPlan.remark" label="备注">
          <el-input size="medium" type="textarea" v-model="form.dealerClientPlan.remark" style="display: inline-block"></el-input>
        </el-form-item>
      </el-card>
      <!-- 客户基础档案 -->
      <el-card class="box-card" style="width:500px;height:700px">
        <div slot="header" class="clearfix">
          <span>客户基础档案</span>
        </div>
        <el-form-item prop="dealerClientRecord.dealerSort" label="新商/老商/二级分销">
          <el-select size="medium" v-model="form.dealerClientRecord.dealerSort" :disabled="addOrEdit !== '0'" clearable>
            <el-option v-for="item in dealerSortList" :key="item.code" :value="item.content" :label="item.content"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="dealerClientRecord.openType" label="开店类型">
          <el-select size="medium" v-model="form.dealerClientRecord.openType" clearable>
            <el-option v-for="item in openTypeList" :key="item.code" :value="item.content" :label="item.content"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="dealerClientRecord.customerName" label="客户姓名">
          <el-input size="medium" v-model="form.dealerClientRecord.customerName" :disabled="addOrEdit !== '0'"/>
        </el-form-item>
        <el-form-item prop="dealerClientRecord.customerPhone" label="客户电话">
          <el-input size="medium" v-model="form.dealerClientRecord.customerPhone" :disabled="addOrEdit !== '0'"></el-input>
        </el-form-item>
        <el-form-item prop="dealerClientRecord.otherBrand" label="经营品牌">
          <el-input size="medium" v-model="form.dealerClientRecord.otherBrand"></el-input>
        </el-form-item>
        <el-form-item prop="dealerClientRecord.industryInvolved" label="所属行业">
          <el-select size="medium" clearable v-model="form.dealerClientRecord.industryInvolved">
            <el-option v-for="item in industryInvolvedList" :key="item.code" :value="item.content" :label="item.content"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="dealerClientRecord.manageDuration"
          label="从事经营时间(年)"
          :rules="form.dealerClientPlan.interviewTime ? rules.manageDuration_required : rules.manageDuration_not_required">
          <el-input size="medium" v-model="form.dealerClientRecord.manageDuration"></el-input>
        </el-form-item>
        <el-form-item
          class="form-item-label-20"
          prop="dealerClientRecord.annualSales"
          label="经营情况(年销售额)(万)"
          :rules="form.dealerClientPlan.interviewTime ? rules.annualSales_required : rules.annualSales_not_required">
          <el-input size="medium" v-model="form.dealerClientRecord.annualSales"></el-input>
        </el-form-item>
        <el-form-item
          class="form-item-label-20"
          prop="dealerClientRecord.oldShopArea"
          label="门店规模(店数/面积)(m2)"
          :rules="form.dealerClientPlan.interviewTime ? rules.oldShopArea_required : rules.oldShopArea_not_required">
          <el-input size="medium" v-model="form.dealerClientRecord.oldShopArea"></el-input>
        </el-form-item>
        <el-form-item
          prop="dealerClientRecord.teamSize"
          label="团队规模(人)"
          :rules="form.dealerClientPlan.interviewTime ? rules.teamSize_required : rules.teamSize_not_required">
          <el-input size="medium" v-model="form.dealerClientRecord.teamSize"></el-input>
        </el-form-item>
        <el-form-item
          prop="dealerClientRecord.locationSign"
          label="是否有意向店面"
          :rules="form.dealerClientPlan.interviewTime ? rules.locationSign_required : rules.locationSign_not_required">
          <el-select size="medium" v-model="form.dealerClientRecord.locationSign" clearable>
            <el-option v-for="item in locationSignList" :key="item.code" :value="item.code" :label="item.content"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="dealerClientRecord.shopType"
          label="门店类型"
          :rules="form.dealerClientRecord.locationSign ? rules.shopType_required : rules.shopType_not_required">
          <el-select size="medium" v-model="form.dealerClientRecord.shopType" clearable>
            <el-option v-for="item in shopTypeList" :key="item.code" :value="item.content" :label="item.content"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="dealerClientRecord.shopArea"
          label="面积(m2)"
          :rules="form.dealerClientRecord.locationSign ? rules.shopArea_required : rules.shopArea_not_required">
          <el-input size="medium" v-model="form.dealerClientRecord.shopArea"></el-input>
        </el-form-item>
        <required-form-item-select
          size="medium"
          label="意向加盟系列"
          prop="dealerClientRecord.intentionSeries"
          labelKey="name"
          valueKey="name"
          getDataListParams="name"
          :rules="form.dealerClientPlan.interviewTime ? rules.intentionSeries_required : rules.intentionSeries_not_required"
          :getDataList="getBrandSeriesList"
          :getNextList="getBrandSeriesList"
          v-model="form.dealerClientRecord.intentionSeries"
          :required="false"
          :selectProps="{
              filterable: true,
              remote: true,
              clearable: true,
              multiple: true
            }"
        />
        <el-form-item
          prop="dealerClientRecord.intentionType"
          label="客户意向分类"
          :rules="form.dealerClientPlan.interviewTime ? rules.intentionType_required : rules.intentionType_not_required">
          <el-select size="medium" v-model="form.dealerClientRecord.intentionType" clearable>
            <el-option v-for="item in intentionTypeList" :key="item.code" :value="item.content" :label="item.content"></el-option>
          </el-select>
        </el-form-item>
      </el-card>
      <!-- 意向城市情况 -->
      <el-card class="box-card" style="width:500px;height:700px">
        <div slot="header" class="clearfix">
          <span>意向城市情况</span>
        </div>
        <el-form-item prop="dealerClientCity.serviceCenter" label="服务中心">
          <el-select size="medium" v-model="form.dealerClientCity.serviceCenter" clearable>
            <el-option v-for="item in serviceCenterList" :key="item.code" :value="item.content" :label="item.content"></el-option>
          </el-select>
        </el-form-item>
        <required-module-form-item-select
          size="medium"
          module="public"
          type="province"
          v-model="form.dealerClientCity.province"
          label="省份"
          value-key="content"
          parentCode="CN"
          prop="dealerClientCity.province"
          :selectListeners="{
              change: () => {
                form.dealerClientCity.cityCode = '';
                form.dealerClientCity.city = '';
                form.dealerClientCity.areaCode = '';
                form.dealerClientCity.area = '';
                form.dealerClientCity.townCode = '';
                form.dealerClientCity.town = '';
              }
            }"
          @changeSelectedItem="(row) => form.dealerClientCity.provinceCode = row && row.code || ''"
        />
        <required-module-form-item-select
          size="medium"
          module="public"
          type="city"
          v-model="form.dealerClientCity.city"
          :parentCode="form.dealerClientCity.provinceCode"
          label="地级市"
          value-key="content"
          prop="dealerClientCity.city"
          id="city"
          :selectListeners="{
              change: () => {
                form.dealerClientCity.areaCode = '';
                form.dealerClientCity.area = '';
                form.dealerClientCity.townCode = '';
                form.dealerClientCity.town = '';
              }
            }"
          @changeSelectedItem="(row) => form.dealerClientCity.cityCode = row && row.code || ''"
          :disabled="!form.dealerClientCity.province"
        />
        <required-module-form-item-select
          size="medium"
          module="public"
          type="area"
          v-model="form.dealerClientCity.area"
          :parentCode="form.dealerClientCity.cityCode"
          label="区县/县级市"
          value-key="content"
          prop="dealerClientCity.area"
          :selectListeners="{
              change: () => {
                form.dealerClientCity.townCode = '';
                form.dealerClientCity.town = '';
              }
            }"
          @changeSelectedItem="(row) => form.dealerClientCity.areaCode = row && row.code || ''"
          :disabled="!form.dealerClientCity.city"
        />
        <required-module-form-item-select
          size="medium"
          module="public"
          type="town"
          v-model="form.dealerClientCity.town"
          :parentCode="form.dealerClientCity.areaCode"
          label="镇"
          value-key="content"
          prop="dealerClientCity.town"
          @changeSelectedItem="(row) => form.dealerClientCity.townCode = row && row.code || ''"
          :disabled="!form.dealerClientCity.area"
          :required="false"
        />
        <el-form-item
          prop="dealerClientCity.urbanEconomy"
          label="空白城市分级"
          :rules="form.dealerClientRecord.openType === '空白' ? rules.urbanEconomy_required : rules.urbanEconomy_not_required">
          <el-select size="medium" v-model="form.dealerClientCity.urbanEconomy" clearable>
            <el-option v-for="item in urbanEconomyList" :key="item.code" :label="item.content" :value="item.content"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="dealerClientCity.levelName"
          label="城市级别(已开城市)"
          :rules="form.dealerClientRecord.openType === '转接' || form.dealerClientRecord.openType === '加密' ? rules.levelName_required : rules.levelName_not_required">
          <el-select size="medium" v-model="form.dealerClientCity.levelName" clearable>
            <el-option v-for="item in levelNameList" :key="item.code" :label="item.content" :value="item.content"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="dealerClientCity.scheduledTime" label="预计开发时间">
          <el-date-picker
            v-model="form.dealerClientCity.scheduledTime"
            type="datetime"
            placeholder="选择首次跟进时间"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <required-form-item-select
          label="负责人"
          prop="dealerClientCity.regionUserName"
          labelKey="userName"
          valueKey="userName"
          getDataListParams="userName"
          :getDataList="getRegionUserNameList"
          :getNextList="getRegionUserNameList"
          v-model="form.dealerClientCity.regionUserName"
          :selectProps="{
            filterable: true,
            remote: true,
            clearable: true
          }"
          @changeSelectedItem="(row) => form.dealerClientCity.regionUserId = row && row.userId || ''"
        />
        <required-form-item-select
          class="form-item-label-20"
          label="客户跟进人(输入工号时请完整输入)"
          prop="dealerClientCity.followUpName"
          labelKey="real_name"
          valueKey="real_name"
          :getDataList="getFollowUserList"
          :getNextList="getFollowUserList"
          v-model="form.dealerClientCity.followUpName"
          :selectProps="{
            filterable: true,
            remote: true,
            clearable: true
          }"
          @changeSelectedItem="(row) => form.dealerClientCity.followUpId = row && row.id || ''"
        />
      </el-card>
    </el-form>
    <div class="demo-drawer__footer">
      <span>备注：带 <span style="color: #ff4949">*</span> 为必填</span>
      <div>
        <el-button style="width: 180px" @click="cancelForm">取 消</el-button>
        <el-button type="primary" style="width: 180px" @click="submit" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import RequiredModuleFormItemSelect from "@/components/CustomFormItem/requiredModuleFormItemSelect";
import requiredFormItemSelect from '../../../../components/CustomFormItem/requiredFormItemSelect';
import { getRegionUserList, getSelectList, getSeriesList, detail, edit, getFollowUserList } from '@/api/attractPlatform/materialManage'
export default {
  components: {
    RequiredModuleFormItemSelect,
    requiredFormItemSelect
  },
  props: [
    'addOrEdit'
  ],
  data: function() {
    const validateNum = (rule, value, callback) => {
      if (value && !/^([1-9][0-9]*|0)(\.[0-9]*[1-9])?$/.test(value)) {
        callback(new Error('请填写数字值'));
      } else {
        callback();
      }
    }
    return {
      drawer: false,
      loading: false,
      rules: {
        'dealerClientPlan.pursuitTime': [
          { required: true, message: '请选择首次跟进时间', trigger: 'blur'}
        ],
        'dealerClientPlan.feedback': [
          { max: 100, message: '最多100个字符', trigger: 'blur' }
        ],
        'dealerClientPlan.otherShop': [
          { max: 50, message: '最多50个字符', trigger: 'blur' }
        ],
        'dealerClientPlan.remark': [
          { max: 300, message: '最多300个字符', trigger: 'blur' }
        ],
        'dealerClientRecord.dealerSort': [
          { required: true, message: '请选择新商/老商/二级分销', trigger: 'change'}
        ],
        'dealerClientRecord.openType': [
          { required: true, message: '请选择开店类型', trigger: 'blur'}
        ],
        'dealerClientRecord.customerName': [
          { required: true, message: '请填写客户姓名', trigger: 'blur'},
          { max: 50, message: '最多50个字符', trigger: 'blur' }
        ],
        'dealerClientRecord.customerPhone': [
          { required: true, message: '请填写客户电话', trigger: 'blur'}
          // { pattern: /(^1\d{10}$)|(^09\d{8}$)/, message: '请输入正确的手机号码' }
        ],
        'dealerClientRecord.otherBrand': [
          { required: true, message: '请填写经营品牌', trigger: 'blur'},
          { max: 100, message: '最多100个字符', trigger: 'blur' }
        ],
        'dealerClientRecord.industryInvolved': [
          { required: true, message: '请选择所属行业', trigger: 'change'}
        ],
        'dealerClientCity.serviceCenter': [
          { required: true, message: '请选择服务中心', trigger: 'change'}
        ],
        'dealerClientCity.provinceCode': [
          { required: true, message: '请选择省份', trigger: 'change'}
        ],
        'dealerClientCity.cityCode': [
          { required: true, message: '请选择地级市', trigger: 'change'}
        ],
        'dealerClientCity.areaCode': [
          { required: true, message: '请选择区县/县级市', trigger: 'change'}
        ],
        'dealerClientCity.scheduledTime': [
          { required: true, message: '请选择预计开发时间', trigger: 'change'}
        ],

        brandCode_required: [
          { required: true, message: '请选择签约系列', trigger: 'change'}
        ],
        brandCode_not_required: [
          { required: false }
        ],
        formNumbers_required: [
          { required: true, message: '请填写开店流程单号', trigger: 'blur' },
          { max: 100, message: '最多100个字符', trigger: 'blur' }
        ],
        formNumbers_not_required: [
          { required: false }
        ],
        planJoinTime_required: [
          { required: true, message: '请选择计划提交加盟时间', trigger: 'change' }
        ],
        planJoinTime_not_required: [
          { required: false }
        ],
        manageDuration_required: [
          { required: true, message: '请填写从事经营时间(年)', trigger: 'blur' },
          { validator: validateNum, trigger: 'change' }
        ],
        manageDuration_not_required: [
          { required: false, validator: validateNum, trigger: 'change' }
        ],
        annualSales_required: [
          { required: true, message: '请填写经营情况(年销售额)(万)', trigger: 'blur' },
          { validator: validateNum, trigger: 'change' }
        ],
        annualSales_not_required: [
          { required: false, validator: validateNum, trigger: 'change' }
        ],
        oldShopArea_required: [
          { required: true, message: '请填写门店规模(店数/面积)(m2)', trigger: 'blur' },
          { validator: validateNum, trigger: 'change' }
        ],
        oldShopArea_not_required: [
          { required: false, validator: validateNum, trigger: 'change' }
        ],
        teamSize_required: [
          { required: true, message: '请填写团队规模(人)', trigger: 'blur' },
          { validator: validateNum, trigger: 'change' }
        ],
        teamSize_not_required: [
          { required: false, validator: validateNum, trigger: 'change' }
        ],
        locationSign_required: [
          { required: true, message: '请选择是否有意向店面', trigger: 'change' }
        ],
        locationSign_not_required: [
          { required: false }
        ],
        shopType_required: [
          { required: true, message: '请选择门店类型', trigger: 'change' }
        ],
        shopType_not_required: [
          { required: false }
        ],
        shopArea_required: [
          { required: true, message: '请填写面积(m2)', trigger: 'blur' },
          { validator: validateNum, trigger: 'change' }
        ],
        shopArea_not_required: [
          { required: false, validator: validateNum, trigger: 'change' }
        ],
        intentionSeries_required: [
          { required: true, message: '请选择意向加盟系列', trigger: 'change' }
        ],
        intentionSeries_not_required: [
          { required: false }
        ],
        intentionType_required: [
          { required: true, message: '请选择客户意向分类', trigger: 'change' }
        ],
        intentionType_not_required: [
          { required: false }
        ],
        urbanEconomy_required: [
          { required: true, message: '请选择空白城市分级', trigger: 'change' }
        ],
        urbanEconomy_not_required: [
          { required: false }
        ],
        levelName_required: [
          { required: true, message: '请选择城市级别(已开城市)', trigger: 'change' }
        ],
        levelName_not_required: [
          { required: false }
        ]
      },

      /* 客户状态 */
      customerStatusList: [{
        code: 0,
        content: '跟进中'
      }, {
        code: 1,
        content: '已加盟'
      }, {
        code: 2,
        content: '已战败'
      }],

      followUpWayList: [],
      passCaseList: [],
      // brandCodeList: [],
      // regionUserNameList: [], // 负责人
      dealerSortList: [],
      openTypeList: [],
      industryInvolvedList: [],
      locationSignList: [{
        code: 0,
        content: '否'
      }, {
        code: 1,
        content: '是'
      }], // 是否有意向店面
      shopTypeList: [],
      // intentionSeriesList: [],
      intentionTypeList: [],
      serviceCenterList: [],
      urbanEconomyList: [],
      levelNameList: [],

      oldData: {},
      form: {
        /* 客户跟踪进度 */
        dealerClientPlan: {
          pursuitTime: '',  // 首次跟进时间
          interviewTime: '',  // 面试通过时间
          recentlyTrailTime: '',  // 最近跟进时间
          followUpWay: '',  // 跟进方式
          feedback: '', // 最近拜访结果反馈
          otherShop: '', // 转化途径
          passCase: '', // 面试通过待走流程情况
          brandName: '',
          brandCode: '', // 签约系列
          formNumbers: '',  // 开店流程单号
          planJoinTime: '', // 计划提交加盟流程时间
          remark: '', // 备注
          // regionUserName: '', // 负责人/招商主管
          // regionUserId: '',
          customerStatus: 0  // 客户状态
        },
        /* 客户基础档案 */
        dealerClientRecord: {
          dealerSort: '', // 经销商分销
          openType: '', // 开店类型
          customerName: '', // 客户姓名
          customerPhone: '',  // 客户电话
          otherBrand: '', // 经营品牌
          industryInvolved: '', // 所属行业
          manageDuration: '', // 从事经营时间（年）
          annualSales: '',  // 经营情况（年销售额）（万）
          oldShopArea: '',  // 门店规模（店数/面积）（㎡）
          teamSize: '', // 团队规模（人）
          locationSign: '', // 是否有意向店面（1是，0否）
          shopType: '', // 门店类型
          shopArea: '', // 面积（㎡）
          intentionSeries: '',  // 意向加盟系列
          intentionType: '',  // 客户意向分类
          createUserId: '',
          createUserName: '', // 创建人名称  登录人
          // customerStatus: '0'  // 客户状态
        },
        /* 意向城市情况 */
        dealerClientCity: {
          serviceCenter: '',  // 服务中心
          province: '',
          provinceCode: '', // 省份
          city: '',
          cityCode: '', // 地级市
          area: '',
          areaCode: '', // 区县/县级市
          town: '',
          townCode: '', // 镇
          urbanEconomy: '', // 空白城市分级
          levelName: '',  // 城市级别（已开城市）
          scheduledTime: '', // 预计开发时间
          regionUserName: '', // 负责人/招商主管
          regionUserId: '',
          followUpId: '', // 客户跟进人
          followUpName: ''
        }
      },
      defaultForm: {
        /* 客户跟踪进度 */
        dealerClientPlan: {
          pursuitTime: '',  // 首次跟进时间
          interviewTime: '',  // 面试通过时间
          recentlyTrailTime: '',  // 最近跟进时间
          followUpWay: '',  // 跟进方式
          feedback: '', // 最近拜访结果反馈
          otherShop: '', // 转化途径
          passCase: '', // 面试通过待走流程情况
          brandName: '',
          brandCode: '', // 签约系列
          formNumbers: '',  // 开店流程单号
          planJoinTime: '', // 计划提交加盟流程时间
          remark: '', // 备注
          // regionUserName: '', // 负责人/招商主管
          // regionUserId: '',
          customerStatus: 0  // 客户状态
        },
        /* 客户基础档案 */
        dealerClientRecord: {
          dealerSort: '', // 经销商分销
          openType: '', // 开店类型
          customerName: '', // 客户姓名
          customerPhone: '',  // 客户电话
          otherBrand: '', // 经营品牌
          industryInvolved: '', // 所属行业
          manageDuration: '', // 从事经营时间（年）
          annualSales: '',  // 经营情况（年销售额）（万）
          oldShopArea: '',  // 门店规模（店数/面积）（㎡）
          teamSize: '', // 团队规模（人）
          locationSign: '', // 是否有意向店面（1是，0否）
          shopType: '', // 门店类型
          shopArea: '', // 面积（㎡）
          intentionSeries: '',  // 意向加盟系列
          intentionType: '',  // 客户意向分类
          createUserId: '',
          createUserName: '', // 创建人名称  登录人
          // customerStatus: '0'  // 客户状态
        },
        /* 意向城市情况 */
        dealerClientCity: {
          serviceCenter: '',  // 服务中心
          province: '',
          provinceCode: '', // 省份
          city: '',
          cityCode: '', // 地级市
          area: '',
          areaCode: '', // 区县/县级市
          town: '',
          townCode: '', // 镇
          urbanEconomy: '', // 空白城市分级
          levelName: '',  // 城市级别（已开城市）
          scheduledTime: '', // 预计开发时间
          regionUserName: '', // 负责人/招商主管
          regionUserId: '',
          followUpId: '', // 客户跟进人
          followUpName: ''
        }
      },
    }
  },
  computed: {
    title: function() {
      if (this.addOrEdit === '0') {
        return '新增客户资料';
      } else if (this.addOrEdit === '1') {
        return '更新客户跟进记录';
      } else if (this.addOrEdit === '2') {
        return '关闭客户';
      }
    }
  },
  async mounted() {
    this.getSelectList();
  },
  methods: {
    beforeClose(done) {
      document.documentElement.style.overflow = '';
      this.setForm();
      done();
    },
    setForm() {
      this.form = JSON.parse(JSON.stringify(this.defaultForm));
    },
    /* 客户跟进人 */
    async getFollowUserList({ page, searchText }) {
      const param = {
        pageSize: 10,
        currPage: page,
        loginType: '10',
        sourceNum: '20'
      }
      if (searchText && /^[0-9]*$/.test(searchText)) {
        param.code = searchText;
      } else if (searchText) {
        param.realName = searchText;
      }
      const res = await getFollowUserList(param);
      if (!res.code) {
        return res.data;
      }
    },
    /* 负责人下拉 */
    async getRegionUserNameList({ page, searchText, getDataListParams }) {
      const param = {
        pageSize: 10,
        currPage: page,
        type: 1,
        [getDataListParams]: searchText
      }
      const res = await getRegionUserList(param);
      if (!res.code) {
        return res.data
      }
    },
    /* 系列下拉 */
    async getBrandSeriesList({ page, searchText, getDataListParams }) {
      const param = {
        pageSize: 10,
        currPage: page,
        brandLevel: '1',
        [getDataListParams]: searchText
      }
      const res = await getSeriesList(param)
      if (!res.code) {
        return res.data
      }
    },
    /* 获取下拉数据 */
    async getSelectList() {
      // 跟进方式下拉
      let param = {
        module: 'client',
        type: 'follow_up_way'
      }
      let res = await getSelectList(param);
      if (!res.code) {
        this.followUpWayList = res.data
      }
      // 面试通过待走流程情况
      param = {
        module: 'client',
        type: 'pass_case'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.passCaseList = res.data
      }
      // 新商/老商/二级分销
      param = {
        module: 'client',
        type: 'dealer_sort'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.dealerSortList = res.data;
      }
      // 开店类型
      param = {
        module: 'client',
        type: 'open_type'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.openTypeList = res.data;
      }
      // 所属行业
      param = {
        module: 'client',
        type: 'industry_involved'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.industryInvolvedList = res.data
      }
      // 门店类型
      param = {
        module: 'client',
        type: 'shop_type'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.shopTypeList = res.data;
      }
      // 客户意向分类
      param = {
        module: 'client',
        type: 'intention_type'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.intentionTypeList = res.data;
      }
      // 服务中心下拉
      param = {
        module: 'client',
        type: 'service_center'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.serviceCenterList = res.data;
      }
      // 空白城市分级
      param = {
        module: 'client',
        type: 'urban_economy'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.urbanEconomyList = res.data;
      }
      // 空白城市分级
      param = {
        module: 'client',
        type: 'authorized_city_level'
      }
      res = await getSelectList(param);
      if (!res.code) {
        this.levelNameList = res.data;
      }
    },
    async getData(id) {
      const res = await detail(id);
      if (!res.code) {
        this.oldData = JSON.parse(JSON.stringify(res.data));
        if (res.data.dealerClientPlan.brandCode) res.data.dealerClientPlan.brandCode =  res.data.dealerClientPlan.brandCode.split(',');
        if (res.data.dealerClientPlan.brandName) res.data.dealerClientPlan.brandName = res.data.dealerClientPlan.brandName.split(',');
        if (res.data.dealerClientRecord.intentionSeries) res.data.dealerClientRecord.intentionSeries = res.data.dealerClientRecord.intentionSeries.split(',');
        this.form = res.data;
      }
    },
    cancelForm() {
      this.setForm();
      document.documentElement.style.overflow = '';
      this.drawer = false;
    },
    async submit() {
      this.loadding = true;
      this.$refs.form.validate(async valid => {
        if (valid) {
          const submitForm = JSON.parse(JSON.stringify(this.form));
          // 签约系列多选处理
          if (submitForm.dealerClientPlan.brandCode) submitForm.dealerClientPlan.brandCode = submitForm.dealerClientPlan.brandCode.join();
          if (submitForm.dealerClientPlan.brandName) submitForm.dealerClientPlan.brandName = submitForm.dealerClientPlan.brandName.join();
          // 意向加盟系列多选处理
          if (submitForm.dealerClientRecord.intentionSeries) submitForm.dealerClientRecord.intentionSeries = submitForm.dealerClientRecord.intentionSeries.join();

          if (this.addOrEdit === '0') { // 新增
            submitForm.type = '1';
          } else if (this.addOrEdit === '1' || this.addOrEdit === '2') {  // 修改、关闭
            if (!this.diffForm(submitForm)) { // 和老数据没有差异
              this.cancelForm();
              return;
            }
          }
          const cloud_userInfo = JSON.parse(localStorage.getItem('cloud_userInfo'));
          if (!submitForm.dealerClientRecord.createUserId) submitForm.dealerClientRecord.createUserId = cloud_userInfo.accountId;
          if (!submitForm.dealerClientRecord.createUserName) submitForm.dealerClientRecord.createUserName = cloud_userInfo.realName;
          const res = await edit(submitForm);

          if (!res.code) {
            this.$message.success("提交成功");
            this.cancelForm();
            this.$emit('getDataList');
          }
        }
      });
      this.loadding = false;
    },
    /* 和修改前数据做对比 */
    diffForm(form) {
      const dealerClientPlan = form.dealerClientPlan;
      const dealerClientRecord = form.dealerClientRecord;
      const dealerClientCity = form.dealerClientCity;
      const old_dealerClientPlan = this.oldData.dealerClientPlan;
      const old_dealerClientRecord = this.oldData.dealerClientRecord;
      const old_dealerClientCity = this.oldData.dealerClientCity;
      for (let item in dealerClientPlan) {
        if (dealerClientPlan[item] !== old_dealerClientPlan[item]) return true;
      }
      for (let item in dealerClientRecord) {
        if (dealerClientRecord[item] !== old_dealerClientRecord[item]) return true;
      }
      for (let item in dealerClientCity) {
        if (dealerClientCity[item] !== old_dealerClientCity[item]) return true;
      }
      return false; // 没有改动数据
    }
  }
}
</script>

<style lang="scss">
.edit-material-container {
  .el-drawer__body {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .c-status {
      font-size: 14px;
      margin: 0 0 20px 10px;
      color: #606266;

      .el-select {
        margin: 0 10px;
      }

      .c-status-remark {
        color: #409eff;
      }
    }

    .el-form {
      display: flex;
      justify-content: space-around;
      width: 100%;
      min-width: 1600px;

      .el-card {
        min-width: 500px;
        display: inline-block;

        .el-card__body {
          overflow: auto;
          height: calc(100% - 55px);

          .el-form-item__label {
            font-size: 13px;
          }

          .form-item-label-20 .el-form-item__label {
            line-height: 20px;
          }
        }
      }
    }

    .demo-drawer__footer {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      font-size: 14px;
    }
  }
}
</style>
