<template>
  <div class="btn">
    <el-popover
      v-model="popoverKey"
      placement="right"
      width="230"
      trigger="click"
    >
      <div>
        <div
          style="
            line-height: 40px;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
          "
        >
          {{ head }}
        </div>
        <el-input
          v-model="searchTitle"
          :placeholder="title"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search(index)"
          />
        </el-input>
        <div>
          <el-radio-group
            v-model="checkedCities"
            v-infinite-scroll="load"
            style="
              margin-top: 15px;
              height: 140px;
              width: 200px;
              overflow: auto;
            "
            class="infinite-list"
          >
            <el-radio
              v-for="(v, i) in searchContent"
              :key="i"
              class="infinite-list-item"
              :label="v.id"
              style="height:30px;line-height:30px; display:block;"
            >
              {{ index===0?v.districtName:v.real_name }} {{v.code}}
            </el-radio>
          </el-radio-group>
          <div style="text-align: right;margin-top:5px;">
            <el-button v-show="showBtn(change)" @click.stop="fixChoose(index)">确定</el-button>
          </div>
        </div>
      </div>
      <!-- <el-button slot="reference" @click="select(index)"> {{ setText(checkedCities) }} </el-button> -->
      <el-button v-show="showBtn(change)" slot="reference" @click="select(index)"> 点击选择</el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['index', 'searchContent', 'head', 'title', 'change'],
  data() {
    return {
      popoverKey: false,
      searchTitle: '',
      checkedCities: '', // 点击数据
      groupList: [] // 选择列表
    }
  },
  computed: {
    setText() {
      return (val) => {
        if (val) {
          return val
        } else {
          return '点击选择'
        }
      }
    },
    showBtn() {
      return  (val) => {
        if (val === 1) {
          return this.index === 0 ? false : true
        } else {
          return true
        }
      }
    }
  },
  methods: {
  // 弹框确定
    fixChoose(index) {
      this.searchTitle = ''
      this.popoverKey = false
      const checkItem = this.searchContent.find(item=>item.id===this.checkedCities)
      
      if (index === 0 && this.checkedCities) {
        this.$emit('readData', checkItem.districtName)
        return
      }
      this.$emit('onComfirm', checkItem)
    },
    // 搜索
    search(index) {
      const obj = {
        searchTitle: this.searchTitle,
        index: index
      }
      this.$emit('searchFn', obj)
    },
    // 点击选择
    select(index) {
      this.searchTitle = ''
      this.search(index)
    },
    // 无限滚动请求
    async load() {
      this.$emit('loadIndex')
    }
  }
}
</script>

<style scoped lang="scss">
.btn{
  background:yellow;
  display: inline-block;
  // width:20%;
}
</style>
