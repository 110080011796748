const ruler = {
  province: [
    { required: true, message: '省份不能空', trigger: 'blur' }
  ],
  city: [
    { required: true, message: '城市不能空', trigger: 'blur' }
  ],
  regionUserName: [
    { required: true, message: '招商主管不能为空', trigger: 'blur' }
  ],
  personLiable: [
    { required: true, message: '经营负责人不能为空', trigger: 'blur' }
  ],
  personLiableTelephone: [
    { required: true, message: '联系方式不能为空', trigger: 'blur' },
    { pattern: /(^1\d{10}$)|(^09\d{8}$)/, message: '请输入正确的手机号码' }
  ]
}

export default ruler
