<template>
  <div class="box">
    <el-form ref="searchForm" :model="searchForm" label-width="80px">
      <el-row :gutter="80">
        <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item label="省份" label-width="40px">
            <el-select v-model="searchForm.province" placeholder="请输入省份">
              <el-option label="全部" :value="''" />
              <el-option
                v-for="(item, index) in provinceList"
                :key="index"
                :label="item.districtName"
                :value="item.districtName"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item label="地级市">
            <el-input
              v-model.trim="searchForm.city"
              placeholder="请输入地级市"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item label="活动名称">
            <el-input
              v-model.trim="searchForm.title"
              placeholder="请输入活动名称"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item label="跟进状态">
            <el-select v-model="searchForm.status" placeholder="请选择跟进状态">
              <el-option label="全部" :value="''" />
              <el-option
                v-for="(item, key, index) in followUpStatusList"
                :key="index"
                :label="item"
                :value="+key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item label="月份" label-width="40px">
            <el-date-picker
              v-model="searchForm.month"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item label="网点类别">
            <el-select v-model="searchForm.type" placeholder="请选择网点类别">
              <el-option label="全部" value=" " />
              <el-option
                v-for="(item, index) in outletsTypeList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item label="经营类别">
            <el-select
              v-model="searchForm.businessCategory"
              placeholder="请选择经营类别"
            >
              <el-option label="全部" value=" " />
              <el-option
                v-for="(item, index) in businessCategoryList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item label="招商主管">
            <el-input
              v-model.trim="searchForm.userName"
              placeholder="请输入招商主管"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="btnBox">
        <div class="btnBox-fle">
          <el-button type="primary" icon="el-icon-search" @click="getList">查询</el-button>          
        </div>
        <div class="btnBox-fle">
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
        <div class="btnBox-fle">
          <el-button type="warning" icon="el-icon-edit" @click="change">修改</el-button>
        </div>
        <div class="btnBox-fle">
          <el-button type="danger" icon="el-icon-delete" @click="delate">删除</el-button>
        </div>
        <div class="btnBox-fle">
          <el-button class="bt-input" type="success" @change="importDate($event)">
            <div class="test" @change="importDate($event)">
              <i class="el-icon-message" />
              <span>导入文档</span>
              <input type="file" class="button-input-add">
            </div>
          </el-button>
        </div>
        <div class="btnBox-fle">
          <el-button type="info" icon="el-icon-message" @click="getTemplate">excel模板下载</el-button>
        </div>
        <!-- <el-button type="primary" icon="el-icon-upload2" @click="getExcelList">导出</el-button> -->
      </div>
    </el-form>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tabelList"
        tooltip-effect="dark"
        style="width: 100%"
        height="560"
        :header-cell-style="{
          background: '#F4F5F8',
          color: '#393F4D',
          textAlign: 'center'
        }"
        :cell-style="{ padding: '8px 0', textAlign: 'center' }"
        @selection-change="handleSelectionChange"
        @row-click="clickList"
      >
        <el-table-column type="selection" width="45" />
        <el-table-column v-for="(item,key,index) in tableKeyValue" :key="index" :prop="key" :label="item" :width="setWidth(key)">
          <template slot-scope="scope">
            <div>
              <div v-if="setNo(scope.row[key]) && setNo(scope.row[key]).length <= 8" class="table-default">
                <div v-if="key=== 'followUpStatus'">{{ followUpStatusList[scope.row[key]] }}</div>
                <div v-else-if="key === 'cooperationStatus'">{{ cooperationStatusList[scope.row[key]] }}</div>
                <div v-else-if="key === 'interviewStatus'">{{ interviewStatusList[scope.row[key]] }}</div>
                <div v-else>{{ setNo(scope.row[key]) }}</div>
              </div>
              <el-tooltip v-else class="item" effect="dark" placement="top-start">
                <div slot="content" style="width:400px; line-height:1.5; font-size: 14px; ">
                  <div v-if="key=== 'followUpStatus'">{{ followUpStatusList[scope.row[key]] }}</div>
                  <div v-else-if="key === 'cooperationStatus'">{{ cooperationStatusList[scope.row[key]] }}</div>
                  <div v-else-if="key === 'interviewStatus'">{{ interviewStatusList[scope.row[key]] }}</div>
                  <div v-else>{{ setNo(scope.row[key]) }}</div>
                </div>
                <div style="width:90%">
                  <div v-if="key=== 'followUpStatus'" class="table-description">{{ followUpStatusList[scope.row[key]] }}</div>
                  <div v-else-if="key === 'cooperationStatus'" class="table-description">{{ cooperationStatusList[scope.row[key]] }}</div>
                  <div v-else-if="key === 'interviewStatus'">{{ interviewStatusList[scope.row[key]] }}</div>
                  <div v-else class="table-description">{{ setNo(scope.row[key]) }}</div>
                </div>
              </el-tooltip>
            </div>
            <!-- <div v-if="key=== 'followUpStatus'">{{ followUpStatusList[scope.row[key]] }}</div>
            <div v-else-if="key === 'cooperationStatus'">{{ cooperationStatusList[scope.row[key]] }}</div>
            <div v-else-if="key === 'outletsName'">
              <div v-if="scope.row[key].length <= 5" class="table-default">{{ scope.row[key] }}</div>
              <el-tooltip v-else class="item" effect="dark" placement="top-start">
                <div slot="content" style="width:400px; line-height:1.5; font-size: 14px; ">{{ scope.row[key] }}</div>
                <div class="table-description" style="width:90%">{{ scope.row[key] }}</div>
              </el-tooltip>
            </div>
            <div v-else>{{ setNo(scope.row[key]) }}</div> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="paginationBar">
        <el-pagination
          background
          layout="total,prev,pager, next,sizes,jumper"
          :total="totalCount"
          :page-sizes="[10, 30, 50]"
          :page-size="pageSize"
          :current-page="currPage"
          @current-change="currentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <addDataManage
      ref="dialog"
      :activity-code-list="activityCodeList"
      :outlets-type-list="outletsTypeList"
      :business-category-list="businessCategoryList"
      :follow-up-status-list="followUpStatusList"
      :cooperation-status-list="cooperationStatusList"
      :interview-status-list="interviewStatusList"
      @getList="getList"
    />
  </div>
</template>

<script>
import {
  manageList,
  excelList,
  downloadTemplate,
  uploadExcel,
  del,
  activeList,
  getRegionUser
} from '@/api/attractPlatform/regionPersonal'
import addDataManage from './addDataManage'
import { getStorage } from '@/utils/storage'
export default {
  components: {
    addDataManage
  },
  data() {
    return {
      searchForm: {
        province: '', // 省
        // channel: '', // 来源渠道
        city: '', // 城市
        status: '', // 状态
        title: '', // 活动名称
        month: '', // 月份
        type: '', // 网店类别
        businessCategory: '', // 经营类别
        userName: '' // 招商主管
      },
      activityCodeList: [], // 活动名称列表
      businessCategoryList: [
        '瓷砖',
        '卫浴',
        '家电',
        '地板',
        '定制',
        '软装',
        '家具',
        '吊顶',
        '装饰公司',
        '整装大家居',
        '门窗',
        '灯具',
        '厨电',
        '其他'
      ], // 经营类别列表
      outletsTypeList: ['KA网点', '购物广场', '独立店', '当地建材市场', '其他'], // 网点列表
      // followUpStatusList: ['跟进中', '已完成', '已战败'], // 跟进状态
      followUpStatusList: {
        1: '跟进中',
        2: '已完成',
        3: '已战败'
      },
      cooperationStatusList: ['未合作', '已合作'], // 合作状态
      interviewStatusList: ['待定', '通过', '不通过'], // 面试状态
      tabelList: [], // 数据列表
      provinceList: [], // 省份列表
      tableKeyValue: { // 表格
        month: '月份',
        province: '省份',
        city: '地级市',
        area: '区县/县级市',
        // outletsName: '网点全称',
        outletsType: '网点类别',
        followUpStatus: '跟进状态',
        regionUserName: '招商主管',
        personLiable: '经营负责人',
        personLiableTelephone: '联系方式',
        title: '活动名称',
        businessCategory: '经营类别',
        otherBrand: '经营品牌',
        derucciBrand: '计划加盟慕思品牌',
        actualDerucciBrand: '实际授权品牌',
        interviewTime: '面试时间',
        interviewStatus: '面试结果',
        remark: '备注',
        cooperationStatus: '合作状态',
        createUserName: '录入人(创建人)'
      },
      multipleSelection: [], // 已勾选数据
      totalCount: 50, // 总条数
      currPage: 1, // 当前页
      pageSize: 10, // 每页条数,
      // userInfo:  getStorage('userInfo') ||{}
    }
  },
  computed: {
    userInfo: () => getStorage('userInfo'),
    setNo(val) {
      return (val) => {
        if (typeof val === 'string' && val.length === 0) {
          return '无'
        } else if (val === undefined) {
          return '无'
        } else if (val === null) {
          return '无'
        } else {
          return val
        }
      }
    },
    setWidth() {
      return (val) => {
        const arr = ['area', 'personLiableTelephone', 'otherBrand', 'createUserName', 'personLiable', 'actualDerucciBrand']
        if (arr.includes(val)) {
          return '120'
        } else if (val === 'derucciBrand') {
          return '140'
        } else {
          return 'auto'
        }
      }
    }
  },
  mounted() {
    console.log(getStorage('userInfo'))
    this.getList()
    this.getActivityCodeList() // 活动名称列表
    this.getRegionUserList() // 省份列表
  },
  methods: {
    // 列表数据
    async getList() {
      const data = {
        userId: this.userInfo.accountId,
        currPage: this.currPage,
        pageSize: this.pageSize,
        ...this.searchForm
      }
      const res = await manageList(data)
      this.tabelList = res.data
      console.log('this.tabelList.derucciBrand', this.tabelList.derucciBrand)
      this.totalCount = res.totalCount
    },
    // 活动列表名称
    async getActivityCodeList() {
      const res = await activeList()
      this.activityCodeList = res.data
    },
    // 管辖地区
    async getRegionUserList() {
      const obj = {
        parentId: 0
      }
      const res = await getRegionUser(obj)
      console.log('res', res)
      this.provinceList = res.data
    },
    // 新增
    add() {
      this.$refs.dialog.dialogVisible = true
      this.$refs.dialog.title = '新增'
      this.$refs.dialog.form.createUserName = this.userInfo.realName
      this.$refs.dialog.form.createUserId = this.userInfo.accountId
    },
    // 修改
    change() {
      console.log('this.multipleSelection', this.multipleSelection)
      this.$refs.dialog.disabledKey = false
      if (
        this.multipleSelection.length > 1 ||
        this.multipleSelection.length === 0
      ) {
        this.$message.error('请选择一条数据')
        return
      }
      const data = this.multipleSelection[0]
      for (const key in data) {
        if (this.$refs.dialog.form.hasOwnProperty(key) && key !== 'derucciBrand' && key !== 'businessCategory') {
          this.$refs.dialog.form[key] = data[key]
        }
        if ((key === 'derucciBrand' || key === 'businessCategory') && data[key] !== '') {
          this.$refs.dialog.form[key] = data[key].split(',')
        }
      }
      this.$refs.dialog.dialogVisible = true
      this.$refs.dialog.title = '修改'
      this.$refs.dialog.form.cooperationStatus
      this.$refs.dialog.getRegionUserNameList()
    },
    // 勾选数据
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log('multipleSelection', this.multipleSelection)
    },
    // 点击所在行打√功能
    clickList(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 页面切换
    currentChange(page) {
      console.log('paga当前页', page)
      this.currPage = page
      const data = {}
      // data.page = this.currPage
      // data.limit = this.pageSize
      this.getList(data)
    },
    // 页数切换
    handleSizeChange(page) {
      console.log('每页条数', page)
      this.pageSize = page
      this.currPage = 1
      const data = {}
      // data.page = this.currPage
      // data.limit = this.pageSize
      this.getList(data)
    },
    // 导出
    async getExcelList() {
      await excelList()
      this.$message({
        type: 'success',
        message: '导出成功'
      })
      // const res = await excelList()
      // console.log('res', res)
      // const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      // const link = document.createElement('a')
      // const blobURL = window.URL.createObjectURL(blob)
      // link.style.display = 'none'
      // link.href = blobURL
      // link.setAttribute('管理', res)
      // document.body.appendChild(link)
      // link.click()
    },
    // 下载模板
    async getTemplate() {
      await downloadTemplate()
      this.$message({
        type: 'success',
        message: '下载成功'
      })
    },
    // 导入数据
    async importDate(event) {
      console.log('event', event)
      const file = event.target.files[0]
      console.log('file', file)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('createUserName', this.userInfo.realName)
      formData.append('createUserId', this.userInfo.accountId)
      try {
        const res = await uploadExcel(formData)
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.data
          })
          event.target.value = ''
          Object.assign(this.$data, this.$options.data())
          this.getList()
        }
      } catch (err) {
        event.target.value = ''
        console.log('err', err)
      }
    },
    // 删除
    async delate() {
      const arr = this.multipleSelection.map((item) => item.id)
      console.log('arr', arr)
      try {
        await this.$confirm('确定删除数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const res = await del(arr)
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.getList()
        }
      } catch (err) {
        this.getList()
        this.multipleSelection = []
      }
    }
  }
}
</script>

<style scoped lang="scss">
// input按键样式
.bt-input {
  padding: 0;
  height: 40px;
  .test {
    position: relative;
    width: 100%;
    padding: 12px 20px;
    // height:100%;
    .button-input-add {
      position: absolute;
      top: 0px;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
.box {
  // padding: 20px 20px 0 20px;
  min-width: 300px;
  /*elemnt 滚动条*/
  ::-webkit-scrollbar {
    width: 4px;
    height: 12px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::v-deep .el-select {
    display: block;
  }
  &::v-deep .el-date-editor.el-input {
    width: 100%;
  }
  &::v-deep .el-form-item--mini.el-form-item {
    // display:flex;
  }
  .table{
    //  table-layout:fixed;
    .table-default{
      width:90%;
      margin-right:10px;
    }
    .table-description{
      // background:yellow;
      width:90%;
      // flex:1;
      height:20px;
      white-space: nowrap;
      margin:0 auto;
      overflow:hidden;
      text-overflow: ellipsis;
      margin-right:10px;
      display: block;
    }
  }
  .btnBox {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width:1000px;
    margin-bottom: 0;
    .btnBox-fle {
      margin-right:10px;
      margin-bottom:20px;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
}
</style>
