<template>
  <div>
    <el-form ref="ruleForm"
             :model="form"
             :rules="rules"
             :inline="true"
             label-position="top">
      <div class="form_item">
        <el-form-item label="活动名称"
                      prop="title"
                      class="item">
          <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="活动代码"
                      class="item">
          <el-input v-model="form.number"
                    :disabled="true" />
        </el-form-item>
      </div>
      <div class="form_item">
        <el-form-item label="活动类型"
                      prop="activityType"
                      class="item">
          <!-- <el-input v-model="form.activityType" /> -->
          <el-select v-model="form.activityType"
                     placeholder="请选择">
            <el-option label="线上"
                       value="线上" />
            <el-option label="线下"
                       value="线下" />
          </el-select>
        </el-form-item>
        <el-form-item label="新客指标数"
                      prop="count"
                      class="item">
          <el-input v-model.number="form.count" />
        </el-form-item>
      </div>
      <div class="form_item">
        <el-form-item label="活动开始时间"
                      prop="startTime"
                      class="item">
          <!-- <el-date-picker
            v-model="pickDate"
            type="datetimerange"
            range-separator="-"
            start-placeholder="活动开始时间"
            end-placeholder="活动结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          /> -->
          <el-date-picker type="datetime"
                          v-model="form.startTime"
                          placeholder="活动开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动结束时间"
                      prop="endTime"
                      class="item">
          <!-- <el-date-picker
            v-model="pickDate"
            type="datetimerange"
            range-separator="-"
            start-placeholder="活动开始时间"
            end-placeholder="活动结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          /> -->
          <el-date-picker type="datetime"
                          v-model="form.endTime"
                          placeholder="活动结束时间">
          </el-date-picker>
        </el-form-item>

      </div>
      <div class="form_item">
        <el-form-item label="省/市"
                      prop="area">
          <div class="form_item"
               style="padding:0">
            <el-select v-model="form.province"
                       placeholder="请选择"
                       class="city"
                       @change="getCity">
              <el-option v-for="item in provinceOptions"
                         :key="item.districtName"
                         :label="item.districtName"
                         :value="item.id" />
            </el-select>
            <el-select v-model="form.city"
                       placeholder="请选择"
                       class="city"
                       @change="getArea">
              <el-option v-for="item in cityOptions"
                         :key="item.districtName"
                         :label="item.districtName"
                         :value="item.id" />
            </el-select>
            <el-select v-model="form.area"
                       placeholder="请选择"
                       class="city">
              <el-option v-for="item in areaOptions"
                         :key="item.districtName"
                         :label="item.districtName"
                         :value="item.id" />
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div class="form_item">
        <el-form-item label="参展人员（招商主管）"
                      prop="regionUserIdList"
                      style="width:100%">
          <el-button v-if="type!='detail'"
                     type="primary"
                     icon="el-icon-plus"
                     style="margin-left:15px"
                     @click="WorkerShow">点击选择参展人员
          </el-button>
          <el-button v-else
                     type="primary"
                     style="margin-left:15px"
                     @click="WorkerShow">查看参展人员
          </el-button>
          <span style="padding:0 20px">{{ form.regionUserIdList.length }}位</span>
        </el-form-item>
      </div>
      <div class="form_item">
        <el-form-item label="详细地址"
                      prop="address"
                      style="flex:1">
          <el-input v-model="form.address" />
        </el-form-item>
      </div>
      <div class="form_item">
        <el-form-item>
          <el-button v-if="type!='detail'"
                     type="primary"
                     @click="save">保存</el-button>
          <el-button @click="back">取消</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-dialog title="参展人员（招商主管）"
               :visible.sync="dialogVisible"
               width="375px"
               :append-to-body="true">
      <el-input v-model="searchKey"
                placeholder="请输入姓名"
                style="margin: 5px 0;"
                @keyup.enter.native="search">
        <el-button slot="append"
                   icon="el-icon-search"
                   @click="search" />
      </el-input>
      <div class="listByWorker">
        <el-checkbox v-model="checkAll"
                     @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 5px 0;" />
        <el-checkbox-group v-model="worker"
                           @change="handleCheckedWorkerChange">
          <div v-for="(item,index) in workerList"
               :key="index"
               class="li">
            <el-checkbox :label="item.id">{{ item.userName }}({{ item.provinceName }}) / {{ item.userPhone }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <div class="pagination">
        <el-pagination background
                       :current-page.sync="currentPage"
                       :page-size="10"
                       layout=" prev, pager, next"
                       :total="total"
                       :small="true"
                       @current-change="handleCurrentChange" />
      </div>
      <span v-show="type!='detail'"
            slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="saveWorker">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEffective,
  getAddress,
  saveData,
  activeDetail
} from '@/api/attractPlatform/active'
// const { id, username } = getStorage('userInfo')
// console.log(id, username)
import { getStorage } from '@/utils/storage'
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchKey: '',
      form: {
        title: '', // 活动标题
        startTime: '',
        endTime: '',
        province: '',
        city: '',
        area: '',
        address: '',
        regionUserIdList: [], // 参展人员
        activityType: '',
        count: '',
        createUserId: '',
        createUserName: '',
        number: ''
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入活动标题',
            trigger: 'blur'
          }
        ],
        activityType: [
          {
            required: true,
            message: '请输入活动类型',
            trigger: 'blur'
          }
        ],
        count: [
          {
            required: true,
            message: '请输入新客指标数',
            trigger: 'blur'
          }
        ],
        regionUserIdList: [
          {
            required: true,
            message: '请选择参展人员（招商主管）',
            trigger: 'change'
          }
        ],
        startTime: [
          {
            required: true,
            message: '请选择活动开始时间',
            trigger: 'change'
          }
        ],
        endTime: [
          {
            required: true,
            message: '请选择活动结束时间',
            trigger: 'change'
          }
        ],
        area: [
          {
            required: true,
            message: '请选择省/市',
            trigger: 'change'
          }
        ],
        address: [
          {
            required: true,
            message: '请输入详细地址',
            trigger: 'blur'
          }
        ]
      },
      worker: [],
      workerList: [], // 人员列表
      dialogVisible: false,
      checkAll: false,
      currentPage: 1,
      total: 0,
      provinceOptions: [], // 城市选项
      cityOptions: [],
      areaOptions: [],
      timer: null // 取消请求的方法
    }
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.getDetail(newVal)
      }
    },
    type(newVal) {
      if (newVal == 'add') {
        this.initData()
      }
    }
  },
  created() {
    const { id, username } = getStorage('userInfo')
    this.form.createUserId = id
    this.form.createUserName = username
    this.getEffectiveData()
    if (this.id) {
      this.getDetail(this.id)
    }
    this.getAddressData()
  },
  methods: {
    //   打开参展人员名单
    WorkerShow() {
      this.dialogVisible = true
      const arr = []
      this.workerList.forEach(item => {
        if (this.worker.includes(item.id)) {
          arr.push(item)
        }
      })
      this.checkAll = arr.length == this.workerList.length
    },
    // 获取详情
    async getDetail(id) {
      const res = await activeDetail({
        activityId: id
      })
      console.log(res)
      const { code, data } = res
      if (!code) {
        for (const key in this.form) {
          if (key != 'regionUserIdList') {
            this.form[key] = data[key]
          }
        }
        this.pickDate = [data.startTime, data.endTime]
        this.form.regionUserIdList = data.activityRegionUserList.map(
          item => item.id
        )
        this.worker = this.form.regionUserIdList
        this.getAddressData(data.province).then(res => {
          this.cityOptions = res
          this.getAddressData(data.city).then(r => {
            this.areaOptions = r
          })
        })
        this.form.activityId = id
      }
    },
    // 获取人员名单
    async getEffectiveData() {
      const res = await getEffective({
        pageSize: 10,
        currPage: this.currentPage,
        sourceNum: 20,
        loginType: 10,
        userName: this.searchKey
      })
      const { code, data, totalCount } = res
      if (!code) {
        this.workerList = data
        this.total = totalCount
      }
    },
    search() {
      this.checkAll = false
      this.currentPage = 1
      this.getEffectiveData().then(() => {
        const arr = []
        this.workerList.forEach(item => {
          if (this.worker.includes(item.id)) {
            arr.push(item)
          }
        })
        this.checkAll = arr.length == this.workerList.length
      })
    },
    // 确定参展人员
    saveWorker() {
      this.form.regionUserIdList = [...this.worker]
      this.dialogVisible = false
      this.$refs.ruleForm.clearValidate('regionUserIdList')
    },
    // 获取地区
    async getAddressData(parentId = 0) {
      const res = await getAddress({
        parentId
      })
      const { code, data } = res
      if (!code) {
        // console.log(data)
        if (parentId === 0) {
          this.provinceOptions = data
        }
        return data
      }
    },
    getCity(val) {
      this.cityOptions = []
      this.areaOptions = []
      this.form.city = ''
      this.form.area = ''
      this.getAddressData(val).then(res => {
        this.cityOptions = res
      })
    },
    getArea(val) {
      this.areaOptions = []
      this.form.area = ''
      this.getAddressData(val).then(res => {
        this.areaOptions = res
      })
    },
    save() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)'
          })
          // this.getImgUrl(this.formData.imageMain).then( r => {

          const url =
            this.type !== 'edit'
              ? '/consumermanage/activitydistributor/add'
              : '/consumermanage/activitydistributor/update'
          try {
            const res = await saveData(url, this.form)
            loading.close()
            if (!res.code) {
              this.$confirm('保存成功', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
              }).then(async () => {
                this.$emit('cancle', true)
              })
            }
          } catch (err) {
            loading.close()
          }
          // })
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    back() {
      this.$emit('cancle', false)
    },
    // 全选
    handleCheckAllChange(val) {
      if (val) {
        this.worker.push(...this.workerList.map(item => item.id))
      } else {
        // 取消删除当前页所有项
        const arr = this.workerList.map(item => item.id)
        arr.forEach(v => {
          // 获取下标
          const index = this.worker.findIndex(i => i == v)
          this.worker.splice(index, 1)
        })
      }
    },
    // 选择某一项
    handleCheckedWorkerChange(val) {
      this.checkAll = val.length == this.workerList.length
    },
    // 切换页面
    handleCurrentChange(val) {
      this.checkAll = false
      this.currentPage = val
      this.getEffectiveData().then(() => {
        const arr = []
        this.workerList.forEach(item => {
          if (this.worker.includes(item.id)) {
            arr.push(item)
          }
        })
        this.checkAll = arr.length == this.workerList.length
      })
    },
    initData() {
      const { id, username } = getStorage('userInfo')
      this.form = {
        title: '', // 活动标题
        startTime: '',
        endTime: '',
        province: '',
        city: '',
        area: '',
        address: '',
        regionUserIdList: [], // 参展人员
        activityType: '',
        count: '',
        createUserId: id,
        createUserName: username
      }
      this.pickDate = []
      this.cityOptions = [] // 城市选项
      this.areaOptions = [] // 城市选项
      this.worker = []
      this.checkAll = false
    }
  }
}
</script>

<style lang='scss' scoped>
.form_item {
  display: flex;
  width: 100%;
  padding: 0 30px;
}

.item {
  width: 30%;
}

.city {
  margin-right: 10px;
  margin-bottom: 10px;
}

.worker {
  display: flex;
  width: 100%;

  .workList {
    min-height: 40px;
    flex: 1;
    border: 1px solid #d7dae2;
    border-radius: 4px;
    padding: 0 5px;
  }
}

.listByWorker {
  // height: 300px;
  margin-bottom: 20px;

  .li {
    margin-bottom: 10px;
  }
}

.pagination {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 1500px) {
  .form_item {
    flex-direction: column;
  }

  .item {
    width: 50%;
  }
}
</style>
