<template>
  <div>
    <!-- <Goback /> -->
    <el-tabs type="border-card">
      <el-tab-pane label="按地区责任人">
        <el-table
          v-show="!isShow"
          ref="filterTable"
          border
          :data="areaData"
          style="width: 100%"
          :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}"
        >
          <el-table-column prop="userName" label="参展人员" align="center" />
          <el-table-column prop="count" label="新客指标" align="center" />
          <el-table-column prop="inputCustomerCount" label="指标数额" align="center" />
          <el-table-column label="收录客户" align="center">
            <template slot-scope="scope">
              <span class="client" @click="clientMsg(scope.row.createUserId)">客户信息</span>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-show="isShow"
          ref="filterTable"
          border
          :data="otherClientData"
          style="width: 100%"
          :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}"
        >
          <el-table-column prop="province" label="省份" align="center" />
          <el-table-column prop="city" label="地级市" align="center" />
          <el-table-column prop="outletsType" label="网点类别" align="center">
            <template slot-scope="scope">
              {{ scope.row.outletsType?scope.row.outletsType:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="personLiable" label="姓名（经营负责人）" align="center">
            <template slot-scope="scope">
              {{ scope.row.personLiable?scope.row.personLiable:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="personLiableTelephone" label="电话" align="center" />
          <el-table-column prop="referrerName" label="推荐人" align="center">
            <template slot-scope="scope">
              {{ scope.row.referrerName?scope.row.referrerName:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="businessCategory" label="经营类别" align="center">
            <template slot-scope="scope">
              {{ scope.row.businessCategory?scope.row.businessCategory:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="otherBrand" label="经营品牌" align="center">
            <template slot-scope="scope">
              {{ scope.row.otherBrand?scope.row.otherBrand:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="derucciBrand" label="计划加盟慕思品牌" align="center">
            <template slot-scope="scope">
              {{ scope.row.derucciBrand?scope.row.derucciBrand:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" />
          <el-table-column prop="createUserName" label="录入人（创建人）" align="center" />
        </el-table>
        <div v-show="isShow">
          <div class="paginationBar">
            <el-pagination
              background
              layout="total,prev, pager, next,sizes,jumper"
              :total="total"
              :page-sizes="[10, 30, 50]"
              :page-size="limit"
              :current-page="page"
              @current-change="currentChange"
              @size-change="handleSizeChange"
            />
          </div>
          <div class="back">
            <el-button type="warning" @click="isShow=false">取消</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="客户明细">
        <el-table
          ref="filterTable"
          border
          :data="clientData"
          style="width: 100%"
          :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}"
        >
          <el-table-column prop="province" label="省份" align="center" />
          <el-table-column prop="city" label="地级市" align="center" />
          <el-table-column prop="outletsType" label="网点类别" align="center">
            <template slot-scope="scope">
              {{ scope.row.outletsType?scope.row.outletsType:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="personLiable" label="姓名（经营负责人）" align="center">
            <template slot-scope="scope">
              {{ scope.row.personLiable?scope.row.personLiable:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="personLiableTelephone" label="电话" align="center" />
          <el-table-column prop="referrerName" label="推荐人" align="center">
            <template slot-scope="scope">
              {{ scope.row.referrerName?scope.row.referrerName:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="businessCategory" label="经营类别" align="center">
            <template slot-scope="scope">
              {{ scope.row.businessCategory?scope.row.businessCategory:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="otherBrand" label="经营品牌" align="center">
            <template slot-scope="scope">
              {{ scope.row.otherBrand?scope.row.otherBrand:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="derucciBrand" label="计划加盟慕思品牌" align="center">
            <template slot-scope="scope">
              {{ scope.row.derucciBrand?scope.row.derucciBrand:'无' }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" />
          <el-table-column prop="createUserName" label="录入人（创建人）" align="center" />
        </el-table>
        <div class="paginationBar">
          <el-pagination
            layout="total,prev, pager, next,sizes,jumper"
            :total="clientTotal"
            :page-sizes="[10, 30, 50]"
            :page-size="clientLimit"
            :current-page="clientPage"
            @current-change="clientCurrentChange"
            @size-change="clientHandleSizeChange"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="back">
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
// import Goback from '@/components/goBack'
import {
  areaGather,
  createUser
} from '@/api/attractPlatform/active'
export default {
  // components: {
  //   Goback
  // },
  data() {
    return {
      areaData: [],
      clientData: [],
      otherClientData: [], // 客户明细
      isShow: false,
      total: 0,
      limit: 10,
      page: 1,
      clientTotal: 0,
      clientLimit: 10,
      clientPage: 1,
      createUserId: null
    }
  },
  created() {
    this.getAareData()
    this.getClientData()
  },
  methods: {
    // 地区负责人
    async getAareData() {
      const {
        id
      } = this.$route.query
      const res = await areaGather({
        activityId: id
      })
      console.log(res)
      if (!res.code) {
        this.areaData = res.data
      }
    },
    // 客户明细
    async getClientData(createUserId, page = 1, size = 10) {
      const {
        id
      } = this.$route.query
      this.createUserId = createUserId
      try {
        const res = await createUser({
          activityId: id,
          createUserId,
          currPage: page,
          pageSize: size
        })
        console.log(res)
        if (!res.code) {
          if (!createUserId) {
            this.clientData = res.data
            this.clientTotal = res.totalCount
          } else {
            this.otherClientData = res.data
            this.total = res.totalCount
          }
        }
      } catch (err) {
        this.isShow = false
      }
    },
    clientMsg(id) {
      this.isShow = true
      this.getClientData(id)
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getClientData(this.createUserId, this.page, this.limit)
      // this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getClientData(this.createUserId, this.page, this.limit)
      // this.getDataList()
    },
    // 页面切换
    clientCurrentChange(page) {
      this.clientPage = page
      this.getClientData(null, this.clientPage, this.clientLimit)
      // this.getDataList()
    },
    // 页数切换
    clientHandleSizeChange(page) {
      this.clientPage = 1
      this.clientLimit = page
      this.getClientData(null, this.clientPage, this.clientLimit)
      // this.getDataList()
    }
  }
}

</script>

<style lang='scss' scoped>
  @import '@/styles/colors';
  @import '@/styles/mixin';

  .client {
    cursor: pointer;
    color: $primary-color;
  }

  .back {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
.paginationBar {
    @include flex(center, center);
    width: 100%;
    height: 60px;
    overflow-x: auto;
  }

  @media(max-width: 1200px) {
    ::v-deep .el-pagination {
      width: 100%;
    }
  }

</style>
