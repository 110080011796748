<template>
  <div class="form">
    <!-- <GoBack /> -->
    <h4>基本设置</h4>
    <el-form :model="form" class="demo-form-inline" label-width="100px">
      <el-form-item label="活动名称">
        <el-col :xs="24" :sm="6">
          <el-input v-model="form.title" readonly />
        </el-col>
      </el-form-item>
      <el-form-item label="活动代码">
        <el-col :xs="24" :sm="6">
          <el-input v-model="form.number" readonly />
        </el-col>
      </el-form-item>
      <el-form-item label="新客指标数">
        <el-col :xs="24" :sm="6">
          <el-input v-model="form.count" readonly />
        </el-col>
      </el-form-item>
      <el-form-item label="参展人员">
        <div class="worker">
          <el-tag v-for="(item, index) in workerList" :key="index" class="works">
            {{ item.userName }}({{ item.provinceName }})</el-tag>
        </div>
      </el-form-item>
      <el-form-item label="活动类型">
        <el-col :xs="24" :sm="6">
          <el-input v-model="form.activityType" readonly />
        </el-col>
      </el-form-item>
      <el-form-item label="活动开始时间">
        <el-date-picker type="datetime" v-model="form.startTime"  readonly />
      </el-form-item>
      <el-form-item label="活动结束时间">
        <el-date-picker type="datetime" v-model="form.endTime"  readonly />
      </el-form-item>
      <el-form-item label="省/市" prop="area">
        <div>
          <el-input v-model="provinceMsg" readonly class="city" />
          <el-input v-model="cityMsg" readonly class="city" />
          <el-input v-model="areaMsg" readonly class="city" />
        </div>
      </el-form-item>
      <el-form-item label="详细地址" prop="area">
        <el-col :span="10">
          <el-input v-model="form.address" readonly />
        </el-col>
      </el-form-item>
    </el-form>
    <h4>客户数据录入</h4>
    <div v-if="$route.query.status != 4&&!$route.query.type" class="btn">
      <div class="file">
        <el-button type="primary">excel导入</el-button>
        <input id="" ref="inputFile" type="file" name="" class="theFile"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="handleFile">
      </div>
      <el-button @click.stop="downLoad">模板下载</el-button>
      <el-button type="danger" :disabled="rowId.length==0" @click="del">删除</el-button>
    </div>
    <el-table ref="filterTable" border :data="otherClientData" style="width: 100%;margin-top:20px"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }" @row-click="rowClick"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="province" label="省份" align="center" />
      <el-table-column prop="city" label="地级市" align="center" />
      <el-table-column prop="outletsType" label="网点类别" align="center">
        <template slot-scope="scope">
          {{ scope.row.outletsType?scope.row.outletsType:'无' }}
        </template>
      </el-table-column>
      <el-table-column prop="personLiable" label="姓名（经营负责人）" align="center">
        <template slot-scope="scope">
          {{ scope.row.personLiable?scope.row.personLiable:'无' }}
        </template>
      </el-table-column>
      <el-table-column prop="personLiableTelephone" label="电话" align="center" />
      <el-table-column prop="referrerName" label="推荐人" align="center">
        <template slot-scope="scope">
          {{ scope.row.referrerName?scope.row.referrerName:'无' }}
        </template>
      </el-table-column>
      <el-table-column prop="businessCategory" label="经营类别" align="center">
        <template slot-scope="scope">
          {{ scope.row.businessCategory?scope.row.businessCategory:'无' }}
        </template>
      </el-table-column>
      <el-table-column prop="otherBrand" label="经营品牌" align="center">
        <template slot-scope="scope">
          {{ scope.row.otherBrand?scope.row.otherBrand:'无' }}
        </template>
      </el-table-column>
      <el-table-column prop="derucciBrand" label="计划加盟慕思品牌" align="center">
        <template slot-scope="scope">
          {{ scope.row.derucciBrand?scope.row.derucciBrand:'无' }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" />
      <el-table-column prop="createUserName" label="录入人（创建人）" align="center" />
    </el-table>
    <div class="paginationBar">
      <el-pagination background layout="total,prev, pager, next,sizes,jumper" :total="total" :page-sizes="[10, 30, 50]"
        :page-size="limit" :current-page="page" @current-change="currentChange" @size-change="handleSizeChange" />
    </div>
    <div class="footer">
      <el-button @click="$router.back()">取消</el-button>
    </div>
  </div>
</template>

<script>
  import {
    activeDetail,
    createUser,
    getAddress,
    delClient,
    importClient
  } from '@/api/attractPlatform/active'
  import {
    download
  } from '@/utils/request'
  import GoBack from '@/components/goBack'

  import {getStorage} from '@/utils/storage'

  export default {
    components: {
      GoBack
    },
    data() {
      return {
        form: {},
        pickDate: [],
        otherClientData: [],
        rowId: [],
        total: 0,
        limit: 10,
        page: 1,
        workerList: [], // 参展人员列表
        provinceMsg: '',
        cityMsg: '',
        areaMsg: ''
      }
    },
    created() {
      // this.getAddressData()
      this.getDetail()
      this.getDataList()
    },
    methods: {
      async getDetail() {
        const {
          activityId
        } = this.$route.query
        const {
          code,
          data
        } = await activeDetail({
          activityId
        })
        if (!code) {
          this.form = data
          this.workerList = data.activityRegionUserList
          this.pickDate = [data.startTime, data.endTime]
          this.getAddressData().then(p => {
            this.$nextTick(() => {
              this.provinceMsg = p.find(item => item.id == data.province).districtName
            })
          })
          this.getAddressData(data.province).then(p => {
            this.$nextTick(() => {
              this.cityMsg = p.find(item => item.id == data.city).districtName
            })
          })
          this.getAddressData(data.city).then(p => {
            this.$nextTick(() => {
              this.areaMsg = p.find(item => item.id == data.area).districtName
            })
          })
        }
      },
      // 获取客户
      async getDataList() {
        const {
          activityId,
          createUserId
        } = this.$route.query
        const res = await createUser({
          activityId,
          createUserId,
          currPage: this.page,
          pageSize: this.limit
        })
        if (!res.code) {
          this.otherClientData = res.data
          this.total = res.totalCount
        }
      },
      // 获取地区
      async getAddressData(parentId = 0) {
        const res = await getAddress({
          parentId
        })
        const {
          code,
          data
        } = res
        if (!code) {
          return data
        }
      },
      // 上传excel文件
      async handleFile(e) {
        const {
          id,
          username
        } = getStorage('userInfo')
        const {
          activityId
        } = this.$route.query
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        formData.append('createUserName', username)
        formData.append('createUserId', id)
        formData.append('activityCode', activityId)
        formData.append('source', 1)
        try {
          const res = await importClient(formData)
          this.$refs.inputFile.value = ''
          if (!res.code) {
            this.$message({
              type: 'success',
              message: res.data
            })
            this.page = 1
            this.getDataList()
          }
        } catch (error) {
          this.$refs.inputFile.value = ''
        }
      },
      // 下载模板
      downLoad() {
        download('consumermanage/distributor/abbreviation/download')
      },
      // 删除
      del() {
        this.$confirm('确定删除此客户信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await delClient(this.rowId)
          if (!res.code) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getDataList()
          }
        })
      },
      handleSelectionChange(val) {
        this.rowId = val.map(item => {
          return item.id
        })
      },
      // 点击行实现点击复选框
      rowClick(row, column, event) {
        this.$refs.filterTable.toggleRowSelection(row)
      },
      // 页面切换
      currentChange(page) {
        this.page = page
        this.getDataList()
      },
      // 页数切换
      handleSizeChange(page) {
        this.page = 1
        this.limit = page
        this.getDataList()
      }
    }
  }

</script>

<style lang="scss" scoped>
  @import '@/styles/mixin';
  @import '@/styles/mixin';

  .form {

    // padding: 20px;
    .worker {
      border: 1px solid #dcdfe6;
      min-height: 40px;
      max-width: 400px;
      border-radius: 4px;
      padding: 0 8px;

      .works {
        margin-right: 5px;
      }
    }
  }

  .city {
    margin-right: 15px;
    width: 250px;
  }

  .btn {
    margin-bottom: 20px;

    .file {
      display: inline-block;
      position: relative;
      margin-right: 10px;

      .theFile {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 100;
        width: 100px;
        height: 40px;
      }
    }
  }

  .footer {
    margin-top: 20px;
    @include flex(center, center);
  }

  .paginationBar {
    @include flex(center, center);
    width: 100%;
    height: 60px;
    overflow-x: auto;
  }

  @media(max-width: 1200px) {
    ::v-deep .el-pagination {
      width: 100%;
    }
  }

</style>
