<template>
  <div class="box">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="500px"
      top="0vh"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="ruler" label-width="130px" style="width:80%;">
        <el-form-item label="省份" prop="province">
          <el-select v-model="form.province" placeholder="请选择省份" @focus="getRegion()" @change="clear(0)">
            <el-option v-for="(item, index) in provinceList" :key="index" :label="item.districtName" :value="item.districtName" />
          </el-select>
        </el-form-item>
        <el-form-item label="地级市" prop="city">
          <el-select v-model="form.city" placeholder="请选择地级市" @focus="getRegion(form.province,1)" @change="clear(1)">
            <el-option v-for="(item, index) in cityList" :key="index" :label="item.districtName" :value="item.districtName" />
          </el-select>
        </el-form-item>
        <el-form-item label="县区/县级市">
          <el-select v-model="form.area" placeholder="请选择县区/县级市" @focus="getRegion(form.city,2)">
            <el-option v-for="(item,index) in areaList" :key="index" :label="item.districtName" :value="item.districtName" />
          </el-select>
        </el-form-item>
        <el-form-item label="招商主管" prop="regionUserName">
          <el-select v-model="form.regionUserName" placeholder="请选择招商主管">
            <el-option v-for="(item,index) in regionUserNameList" :key="index" :label="item.userName" :value="item.userName" />
          </el-select>
        </el-form-item>
        <el-form-item label="网点类别">
          <el-select v-model="form.outletsType" placeholder="请选择网点类别">
            <el-option v-for="(item, index) in outletsTypeList" :key="index" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="跟进状态">
          <el-select v-model="form.followUpStatus" :disabled="disabledKey" placeholder="请选择活动区域">
            <el-option v-for="(item, key, index) in followUpStatusList" :key="index" :label="item" :value="+key" />
          </el-select>
        </el-form-item>
        <el-form-item label="经营负责人" prop="personLiable">
          <el-input v-model.trim="form.personLiable" placeholder="请输入经营负责人" />
        </el-form-item>
        <el-form-item label="联系方式" prop="personLiableTelephone">
          <el-input v-model.trim="form.personLiableTelephone" placeholder="请输入联系方式" />
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-if="title ==='修改' && setActiveCode(form.activityCode)" v-model="form.title" :disabled="true" />
          <el-select v-else v-model="form.activityCode" placeholder="请选择活动名称">
            <el-option v-for="(item, index) in activityCodeList" :key="index" :label="item.title" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="经营类别">
          <el-select v-model="form.businessCategory" multiple filterable allow-create placeholder="请选择经营类别">
            <el-option v-for="(item,index) in businessCategoryList" :key="index" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="经营品牌">
          <el-input v-model.trim="form.otherBrand" placeholder="请输入经营品牌" />
        </el-form-item>
        <el-form-item label="计划加盟慕思品牌">
          <el-select v-model="form.derucciBrand" multiple filterable allow-create placeholder="请选择计划加盟慕思品牌">
            <el-option v-for="(item,index) in derucciBrandList" :key="index" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="合作状态">
          <el-select v-model="form.cooperationStatus" placeholder="请选择合作状态">
            <el-option v-for="(item,index) in cooperationStatusList" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <el-form-item label="实际授权品牌">
          <el-select v-model="form.actualDerucciBrand" multiple filterable allow-create placeholder="请选择实际授权品牌">
            <el-option v-for="(item,index) in derucciBrandList" :key="index" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="面试时间">
          <!-- <el-input v-model.trim="form.interviewTime" placeholder="请输入面试时间" /> -->
          <el-date-picker
            v-model="form.interviewTime"
            type="date"
            placeholder="请输入面试时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="面试结果">
          <el-select v-model="form.interviewStatus" placeholder="请选择面试结果">
            <el-option v-for="(item,index) in interviewStatusList" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="form.remark" placeholder="请输入备注" />
        </el-form-item>
        <el-form-item label="录入人(创建人)">
          <el-input v-model.trim="form.createUserName" :disabled="true" placeholder="请输入录入人" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addForm('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getRegionUser, regionList, postForm, postChangeForm } from '@/api/attractPlatform/regionPersonal'
import reler from './ruler'
import {getStorage} from '@/utils/storage'
export default {
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'activityCodeList', // 活动名称列表
    // eslint-disable-next-line vue/require-prop-types
    'outletsTypeList', // 网点列表
    // eslint-disable-next-line vue/require-prop-types
    'businessCategoryList', // 经营类别列表
    // eslint-disable-next-line vue/require-prop-types
    'followUpStatusList', // 跟进状态
    // eslint-disable-next-line vue/require-prop-types
    'cooperationStatusList', // 合作状态
    // eslint-disable-next-line vue/require-prop-types
    'interviewStatusList' // 面试结果
  ],
  data() {
    return {
      dialogVisible: false,
      dialogKey: false,
      title: '',
      disabledKey: true, // 是否禁止选择
      provinceList: [], // 省份列表,
      cityList: [], // 城市列表,
      areaList: [], // 区县列表
      regionUserNameList: [], // 招商主管列表,
      // cooperationStatusList: ['已合作', '未合作'], // 合作状态
      derucciBrandList: ['3D', '凯奇', '0769', '歌蒂娅', '儿童', 'V6', 'Bedgea', 'V6家居', '苏斯', 'Pauly', '慕思国际', 'slumberzone', 'MDL', 'DeRUCCI HOME', '慕思芯睡眠', '助眠系列', '慕思助眠', '慕思美居', 'Calia Italia', 'Calia Sofart', '电商沙发', '慕思真皮沙发', '酒店工程家居', '海外家居', '渠道沙发', '慕思布艺沙发'],
      form: {
        id: '',
        province: '', // 省份
        city: '', // 地级市
        area: '', // 区县/县级市
        regionUserId: '', // 招商主管id
        regionUserName: '', // 招商主管
        outletsType: '', // 网点类别
        personLiable: '', // 经营负责人
        personLiableTelephone: '', // 联系方式
        title: '', // 活动名称
        activityCode: '', // 活动名称Id
        businessCategory: '', // 经营类别
        otherBrand: '', // 经营品牌
        derucciBrand: '', // 计划加盟慕思品牌
        followUpStatus: 1, // 跟进状态/默认0跟进中
        cooperationStatus: null, // 合作状态
        actualDerucciBrand: '', // 实际授权品牌
        interviewTime: '', // 面试时间
        interviewStatus: '', // 面试结果（0待定1通过2不通过）
        remark: '', // 备注
        createUserName: '', // 创建人
        createUserId: '' // 创建人ID
      },
      ruler: reler // 规则
    }
  },
  computed: {
    setProvinceCode(provice) {
      return (provice) => {
        return this.provinceList.filter(item => item.districtName === provice)[0].id
      }
    },
    setCityCode(city) {
      return (city) => {
        return this.cityList.filter(item => item.districtName === city)[0].id
      }
    },
    setActiveCode() {
      return (val) => {
        console.log('val', val, this.activityCodeList.some(item => item.id === this.form.activityCode))
        if (val) {
          if (this.activityCodeList.some(item => item.id === this.form.activityCode)) {
            return false
          } else {
            return true
          }
        }
        return false
      }
    }
  },
  mounted() {
    console.log(2222, this.form.cooperationStatus)
    this.getRegion()
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false
      this.disabledKey = true
      this.form = this.$options.data().form
      // done()
    },
    // 省、市、区列表
    async getRegion(val, index = 0) {
      const data = {}
      if (index === 0) {
        data.parentId = 0
      } else {
        data.districtName = val
      }
      const res = await getRegionUser(data)
      if (index === 0) {
        this.provinceList = res.data
      }
      if (index === 1) {
        this.cityList = res.data
        this.getRegionUserNameList()
      }
      if (index === 2) {
        this.areaList = res.data
      }
    },
    // 选择框修改
    clear(index) {
      if (index === 0) {
        this.form.city = ''
        this.form.area = ''
      }
      if (index === 1) {
        this.form.area = ''
      }
    },
    // 招商主管列表
    async getRegionUserNameList() {
      const data = {
        provinceName: this.form.province
      }
      const res = await regionList(data)
      this.regionUserNameList = res.data
    },
    // 确定提交表单
    async addForm(formName) {
      // const data = this.form
      this.form.derucciBrand = this.form.derucciBrand.toString()
      this.form.businessCategory = this.form.businessCategory.toString()
      this.form.actualDerucciBrand = this.form.actualDerucciBrand.toString()
      if (this.check(formName)) {
        return
      }
      let data = {}
      if (this.title === '新增') {
        for (const key in this.form) {
          if (key !== 'id' && key !== 'regionUserId') {
            data[key] = this.form[key]
           
          }
          if (key === 'regionUserId') {
            data[key] = this.regionUserNameList.filter(item => item.userName === this.form.regionUserName)[0].id
          }
        }
        console.log(getStorage('userInfo'))
         data.createUserId=getStorage('userInfo')['accountId']||getStorage('userInfo')['id']
      } else {
        data = this.form
      }
      const res = this.title === '新增' ? await postForm(data) : await postChangeForm(data)
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: `${this.title}成功`
        })
        this.dialogVisible = false
      }
      this.$emit('getList') // 刷新数据
      this.form = this.$options.data().form
    },
    // 检查表单
    check(formName) {
      let key = ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('valid', valid, this.form)
          key = false
        } else {
          console.log('错误')
          key = true
        }
      })
      return key
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  .el-dialog__wrapper{
    &::v-deep .el-dialog{
      height:100vh;
      overflow: auto;
    }
  }
  &::v-deep .el-dialog__body{
    padding:10px 20px;
  }
}
</style>
