<template>
  <el-drawer title="门店数据修改详情"
             :visible.sync="dialogShow"
              v-bind="$attrs" 
              v-on="$listeners"
             direction="rtl"
             size="50%;overflow-y:auto">
    <el-form ref="form" v-loading="loading" 
    label-position="left"
             label-width="200px"
             style="padding:0 30px;width:100%;"
             class="form">
       <el-form-item label="门店编号" >
       <div class="items">
          {{shopsModel.shopNumber}}
       </div>
      </el-form-item>
       <el-form-item label="门店名称" >
        <div class="items">{{shopsModel.shopName}}</div>
      </el-form-item>
      <el-form-item label="授权城市名称">
        <div class="items">
          <div class="content">{{shopsModel.authorizedCityName}}</div>
        </div>
      </el-form-item>
      <el-form-item label="省市区">
        <div class="items">
          <div class="content">{{`${shopsModel.province}-${shopsModel.city}-${shopsModel.area}`}}</div>
        </div>
      </el-form-item>
      <el-form-item label="城市等级">
        <div class="items">
          <div class="content">{{shopsModel.marketLevelName}}</div>
        </div>
      </el-form-item>
      <el-form-item label="门店位置等级">
        <div class="items">
          <div class="content">{{oPositionLevel[shopsModel.shopPositionLevel]}}</div>
          <div class="update" v-if="dealerUpdateShop.shopPositionLevel"> <span> 修改至 </span> <div>{{oPositionLevel[dealerUpdateShop.shopPositionLevel]}}</div></div>
        </div>
      </el-form-item>
      <el-form-item label="否独立门头">
        <div class="items">
          <div class="content">{{shopsModel.independentDoorHeadFlag ? '是' : '否'}}</div>
          <div class="update" v-if="typeof(dealerUpdateShop.independentDoorHeadFlag) =='boolean'"> <span> 修改至 </span> <div>{{dealerUpdateShop.independentDoorHeadFlag? '是' : '否'}}</div></div>
        </div>
      </el-form-item>
      <el-form-item label="门店经度">
        <div class="items">
          <div class="content">{{shopsModel.longitude}}</div>
          <div class="update" v-if="dealerUpdateShop.longitude"> <span> 修改至 </span> <div>{{dealerUpdateShop.longitude}}</div></div>
        </div>
      </el-form-item>
      <el-form-item label="门店纬度">
        <div class="items">
          <div class="content">{{shopsModel.latitude}}</div>
          <div class="update" v-if="dealerUpdateShop.latitude"> <span> 修改至 </span> <div>{{dealerUpdateShop.latitude}}</div></div>
        </div>
      </el-form-item>
      <el-form-item label="门店地址">
        <div class="items">
          <div class="content">{{`${shopsModel.province}${shopsModel.city}${shopsModel.area}${
          shopsModel.street || ''
        }${shopsModel.shopFloor || ''}${shopsModel.shopFloorNumber || ''}`}}</div>
        </div>
      </el-form-item>
      <el-form-item label="渠道性质">
        <div class="items">
          <div class="content">{{shopType}}</div>
          <div class="update" v-if="updateShopType"> <span> 修改至 </span> <div>{{updateShopType}}</div></div>
        </div>
      </el-form-item>
      <el-form-item label="渠道性质二级分类">
       <div class="items">
         <div class="content">{{shopsModel.shopTypeValue}}</div>
         <div class="update" v-if="dealerUpdateShop.shopTypeValue"> <span> 修改至 </span> <div>{{dealerUpdateShop.shopTypeValue}}</div></div>
       </div>
      </el-form-item>
       <el-form-item label="门店授权系列：">
        <div class="items">
          <div class="content">{{getBrandListSum('name')}}</div>
        </div>
      </el-form-item>
       <el-form-item label="门店总面积：">
        <div class="items">
          <div class="content">{{getBrandListSum('brandArea')}}</div>
        </div>
      </el-form-item>

      <el-form-item label="店态性质" >
        <div class="items">{{storeNature}}</div>
        <div class="update" v-if="updateStoreNature"> <span> 修改至 </span> <div>{{updateStoreNature}}</div></div>
      </el-form-item>
      <el-form-item label="装修到期时间">
        <div class="items">{{shopsModel.nextdecorationtime}}</div>
      </el-form-item>
      <el-form-item label="门店状态">
        <div class="items">{{oStoreStatus[shopsModel.statusExplain]}}</div>
      </el-form-item>

      <el-form-item label="经营模式">
        <div class="items">
          <div class="content">{{management}}</div>  
        </div>
      </el-form-item>
      
      <el-form-item label="门店租金（万元/年）">
        <div class="items">
          <div class="content">{{shopsModel.shopRent}}</div>
           <div class="update" v-if="dealerUpdateShop.shopRent"><span> 修改至 </span> <div>{{dealerUpdateShop.shopRent}}</div></div>
        </div>
      </el-form-item>
       <el-form-item label="最新装修时间">
        <div class="items">{{shopsModel.decorationTime}}</div>
      </el-form-item>
      <el-form-item label="开业时间">
        <div class="items">
          <div class="content">{{shopsModel.oldOpeningTime}}</div>
          <div class="update" v-if="dealerUpdateShop.openingTime"><span> 修改至 </span> <div>{{dealerUpdateShop.openingTime}}</div></div>
        </div>
      </el-form-item>
      
      <el-form-item label="门店面积/样品套数">
       <div>
          {{shopsModel.shopArea}}㎡  / {{shopsModel.shopSampleSets}}套 <el-button type="primary" size="mini" @click="dialogTableVisible=true">查看更多</el-button>
       </div>
      </el-form-item>
       <el-form-item label="门店联系电话">
        <div class="items">{{shopsModel.shopTel}}</div>
         <div class="update" v-if="dealerUpdateShop.shopTel"><span> 修改至 </span> <div>{{dealerUpdateShop.shopTel}}</div></div>
      </el-form-item>
      <el-form-item label="备注">
        <p>{{dealerUpdateShop.remark}}</p>
      </el-form-item>
    </el-form>
    <el-dialog title="门店系列面积" :visible.sync="dialogTableVisible" :append-to-body="true">
      <el-table :data="brandsModelList" :header-cell-style="{ textAlign:'center' }" :cell-style="{ textAlign: 'center' }">
        <el-table-column property="name"   label="系列" />
        <el-table-column property="brandArea" label="系列面积"  >
          <template slot-scope="scope">
            <div class="area">
              {{scope.row.brandArea}} <div v-if="scope.row.update.brandArea" class="updated"><span> 修改至 </span>{{scope.row.update.brandArea}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column property="sampleSets" label="样品套数">
          <template slot-scope="scope">
            <div class="area">
              {{scope.row.sampleSets}} <div v-if="scope.row.update.sampleSets" class="update-list updated"><span> 修改至 </span> {{scope.row.update.sampleSets}}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-drawer>
</template>

<script>
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import {
  getShopUpdate,
  getBossDataList,
  crmdistributorListPage,
  shopInfoDetail,
  authorizedcityListpage
} from '@/api/businessOos/'

import {classificationInfo,datadictionaryListpage} from '@/api/attractPlatform/storeData'

const management = {
  // 10: "一级经销",
  10: "一级经销",
  20: "二级经销",
  30: "三级经销",
  40: "直营",
};

const partnership = {
  10: "直营",
  20: "经销",
  30: "零售",
  40: "电商",
  50: "工程",
  60: "地产自营拎包",
  70: "商超",
  90: "公司间",
};
const oStoreStatus = {
        9: "待设计",
        10: "设计中",
        20: "装修中",
        30: "装修完成",
        40: "正常营业",
        50: "装修失效",
        60: "撤店失效",
        70: "转让失效",
      };

const oPositionLevel ={
   1: "A+",
        2: "A",
        3: "B",
        4: "C",
        5: "其他",
}
export default {
  props: ['storeVisibleDialog', 'cancel', 'storeItem'],
  components: {
    RequiredModuleFormItemSelect,
    RequiredFormItemInput,
    RequiredFormItemSelect
  },
  data() {
    return {
      storeForm:{},
      detail:{},
      shopsModel:{},
      dealerUpdateShop:{},
      brandsModelList:[],
      updateShopBrandList:[],
      loading:false,
      storeNature:'',
      updateStoreNature:'',
      shopType:'',
      updateShopType:'',
      dialogTableVisible:false,
      partnership:'',
      management:'',
      oStoreStatus,
      oPositionLevel,
      dictionaryList:[]
    }
  },
  watch: {
    storeItem(obj) {
      this.updateShopType = ''
      this.getDetail(obj.id)
    }
  },
  computed: {
      dialogShow: {
        get() {
          return this.storeVisibleDialog
        },
        set() {
          this.cancel()
        },
      },
      shopStatus(){
        const status = {
          '9': "待设计",
          '10': "设计中",
          '20': "装修中",
          '30': "装修完成",
          '40': "正常营业",
          '50': "装修失效",
          '60': "撤店失效",
          '70': "转让失效",
        };
        return status[this.shopsModel.statusExplain];
      }
  },
  methods: {
    getBrandListSum(sKey){
      let sum = 0
      if(sKey=='name') sum =''
      for(const item of this.brandsModelList) {
        if(sKey=='name'){
          sum+=item[sKey]+' '
        }else{
          sum+=item[sKey]
        }
        
      }
      return sum
    },
    getDetail(id){
      this.loading=true
      classificationInfo(id).then(res=>{
          this.loading=false
          this.detail=res.data
          const {shopsModel={},dealerUpdateShop={},brandsModelList=[],updateShopBrandList=[]} = res.data
          this.shopsModel=shopsModel
          this.dealerUpdateShop=dealerUpdateShop
          this.brandsModelList=brandsModelList.map(item=>({...item,update:updateShopBrandList.find(items=>item.id==items.shopBrandId)||{}}))
          //合作关系
          this.partnership = partnership[shopsModel.partnership] || "";
          //经营模式
           this.management = management[shopsModel.managementModel] || "";

          //  datadictionaryListpage({
          //     type: "shop_nature_code",
          //     module: "distributor",
          //     currPage: 1,
          //   }).then(({ data }) => {
          //     const obj = data.find((item) => item.code == shopsModel.storeNature)||{};
          //     this.storeNature = obj.content||'';
          //   });

          datadictionaryListpage({type:'shop_nature_code',module: "distributor",currPage: 1}).then(({data})=>{
            const obj = data.find((item) => item.code == this.shopsModel.storeNature) || {}
            this.storeNature = obj.content
            if(this.dealerUpdateShop.storeNature){
              const oUpdate = data.find((item) => item.code == this.dealerUpdateShop.storeNature) || {}
              this.updateStoreNature = oUpdate.content
            }
          })
          datadictionaryListpage({type:'store_type',module: "distributor",currPage: 1}).then(({data})=>{
            const oShopType = data.find((item) => item.code == this.shopsModel.shopType) || {}
            this.shopType = oShopType.content
            if(this.dealerUpdateShop.shopType){
              const oUpdateShopType = data.find((item) => item.code == this.dealerUpdateShop.shopType) || {}
              this.updateShopType =  oUpdateShopType.content
            }
          })
      }).catch(()=>this.loading=false)
    },
    getDatadictionaryList(ops,type,shopsModel){
      datadictionaryListpage({
          type,
          module: "distributor",
          currPage: 1,
        }).then(({ data }) => {
          const obj = data.find((item) => item.code == shopsModel[name]);
          this[name] = obj.content;
          console.log(this.shopType, obj.content)
        });
    },
    async getShopInfoDetail() {
      const res = await shopInfoDetail({ id: this.selectionId })
      this.storeForm = res.data
    },
    // 授权城市列表
    async getAuthorizedCityList({ page, searchText }) {
      return authorizedcityListpage({
        currPage: page,
        addr: searchText
      }).then(res => {
        this.authorizedCityCodeTotal = res.totalCount
        return res.data
      })
    },
    // 默认第一页没有找到授权城市时调用
    async onNotFoundCityList({ value: authorizedCityCode }, callback) {
      callback(
        await authorizedcityListpage({
          authorizedCityCode
        }).then(res => res.data)
      )
    },
    // 获取实控人列表
    async getbossIdList({ page, searchText }) {
      return getBossDataList({ currPage: page, realName: searchText }).then(
        res => {
          this.bossIdCountTotal = res.totalCount
          return res.data
        }
      )
    },
    // 默认第一页没有找到实控人
    async onNotFoundBossList({ value: id }, callback) {
      callback(
        await getBossDataList({
          id
        }).then(res => res.data)
      )
    },
    // 获取售达方列表
    async getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      }).then(res => {
        this.businessCountTotal = res.totalCount
        return res.data
      })
    },
    async onNotFoundBusinessLicenseId({ value: id }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId: id
        }).then(res => res.data)
      )
    },
    onSubmit() {
        const userInfo = getStorage('userInfo')
      getShopUpdate({
        ...this.storeForm,
        updateUserName: userInfo?.realName
      }).then(res => {
        this.$message.success('保存成功')
        this.cancel()
        this.$emit('onUpdateStore')
      })
    },
    changeShopType() {
      // this.storeForm.shopTypeValue = ''
      // this.$nextTick(() => {
      //   this.$refs['flowFormRef'].$refs['ruleForm'].clearValidate(
      //     'dealeShop.shopTypeValue'
      //   )
      // })
    }
  }
}
</script>

<style lang="scss">
.area{
  display: flex;
  justify-content: center;
  .updated{
    color: red;
  }
  span{
    color: #999;
  }
}
.form {
  .el-form-item {
   border-bottom: 1px solid #eeeeee;
   padding-bottom: 10px;
   margin-bottom: 10px;
  }
  .el-form-item__content{
    display: flex;
    .items{
    text-align: left;
    display: flex;
    justify-content: space-between;
    .content{
      min-width: 100px;
    }
    
  }
  .update{
    min-width: 300px;
      padding-left: 15px;
      flex:1;
      display: flex;
      div{
        padding-left: 10px;
        color: red;
      }
      span{
        color: #999;
      }
    }
  }
   
  .el-form-item__label{
    text-align: right;
  }
  .update-list{
    color: red;
    span{
      color: #999;
    }
  }
  // .el-form-item__content,
  // .el-form-item__label {
  //   line-height: 20px;
  //   text-align: right;
  //   display: block;
  //   .el-date-editor.el-input {
  //     width: 100%;
  //   }
  // }
  .el-select {
    width: 100%;
  }
}
</style>