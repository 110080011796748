<template>
  <div class="active">
    <el-form :model="q" :inline="true">
      <el-form-item label="招商名称：">
        <el-input v-model="q.title" />
      </el-form-item>
      <el-form-item label="活动代码：">
        <el-input v-model="q.number" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="query">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="filterTable" border :data="dataList" style="width: 100%"
      :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}">
      <el-table-column prop="number" label="活动代码" align="center" />
      <el-table-column prop="title" label="招商名称" align="center" />
      <el-table-column prop="activityType" label="活动类型" align="center" width="120" />
      <el-table-column prop="status" label="活动状态" align="center" width="120">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status==2" type="warning">下发</el-tag>
            <el-tag v-else-if="scope.row.status==3" type="danger">撤回</el-tag>
            <el-tag v-else type="success">完成</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createUserName" label="创建人" align="center" width="150" />
      <el-table-column prop="createTime" label="创建时间" align="center" width="200" />
      <!-- <el-table-column prop="createTime" label="提交状态" align="center" width="160" /> -->
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.status!=4" class="client"
            @click="clientMsg(scope.row.activityId,scope.row.status)">录入客户信息</span>
          <span class="client" style="margin:0 10px" @click="clientMsg(scope.row.activityId,scope.row.status,'detail')">详情</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="paginationBar">
      <el-pagination layout="total,prev, pager, next,sizes,jumper" :total="total" :page-sizes="[10, 30, 50]"
        :page-size="limit" :current-page="page" @current-change="currentChange" @size-change="handleSizeChange" />
    </div>
  </div>
</template>

<script>
  import {
    getActiveData
  } from '@/api/attractPlatform/active'
  import {getStorage} from '@/utils/storage'
  export default {
    data() {
      return {
        q: {},
        dataList: [],
        total: 0,
        limit: 10,
        page: 1
      }
    },
    created() {
      this.getDataList()
    },
    methods: {
      async getDataList() {
        const {
          id
        } = getStorage('userInfo')
        const res = await getActiveData({
          pageSize: this.limit,
          currPage: this.page,
          userId: id,
          ...this.q
        })
        const {
          data,
          code,
          totalCount
        } = res
        if (!code) {
          this.dataList = data
          this.total = totalCount
        }
      },
      // 客户信息
      clientMsg(activityId, status,type="") {
        const {
          id
        } = getStorage('userInfo')
        this.$router.push({
          path: '/attractPlatform/createClient',
          query: {
            activityId,
            createUserId: id,
            status,
            type
          }
        })
      },
      // 重置
      reset() {
        this.q = {}
        this.page = 1
        this.getDataList()
      },
      // 查询
      query() {
        this.page = 1
        this.getDataList()
      },
      // 页面切换
      currentChange(page) {
        this.page = page
        this.getDataList()
      },
      // 页数切换
      handleSizeChange(page) {
        this.page = 1
        this.limit = page
        this.getDataList()
      }
    }
  }

</script>

<style lang='scss' scoped>
  @import '@/styles/colors';
  @import '@/styles/mixin';

  .client {
    cursor: pointer;
    color: $primary-color;
  }

  .paginationBar {
    @include flex(center, center);
    width: 100%;
    height: 60px;
    overflow-x: auto;
  }

  @media(max-width: 1200px) {
    ::v-deep .el-pagination {
      width: 100%;
    }
  }

</style>
