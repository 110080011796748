<template>
  <div>
    <div ref="form">
      <el-form :inline="true"
               :model="formInline"
               class="demo-form-inline">
        <el-form-item v-for="item in formInlineData"
                      :key="item.key"
                      :label="`${item.label}：`">
          <el-input v-model="formInline[item.key]"
                    :placeholder="`请输入${item.label}`"
                    @keyup.enter.native="query"></el-input>
        </el-form-item>
        <el-form-item label="录入日期">
             <el-date-picker
                v-model="selectDate"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
            <el-select v-model="statusValue" placeholder="请选择" @change="statusChange">
                <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="query">查询</el-button>
                     <el-button type="default"
                     @click="reset">重置</el-button>
                     <el-button type="default"
                     @click="handleExport">导出</el-button>
        </el-form-item>
        <el-form-item>
          <!-- <el-button @click="downloadExcel" v-permission="['business_excel']">导出excel</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div :style="{'height':`calc(100vh - ${withoutTableHeight}px)`}">
      <el-table ref="table"
                border
                align="center"
                :data="dataList"
                style="width: 100%"
                :header-cell-style="{ background: '#F4F5F8', color: '#393F4D',textAlign:'center' }"
                :cell-style="{ textAlign: 'center' }"
                height="100%"
                v-loading="loading"
                :show-overflow-tooltip="true">
        <el-table-column prop="account"
                         label="录入人工号"
                         
                         :show-overflow-tooltip="true" />
        <el-table-column prop="username"
                         label="录入人姓名"
                        
                         :show-overflow-tooltip="true" />
        <el-table-column prop="shopNumber"
                         label="录入门店编号"
                        
                         :show-overflow-tooltip="true" />
        <el-table-column prop="createTime"
                         label="录入时间"
                        
                         :show-overflow-tooltip="true" />
        <el-table-column prop="remark"
                         label="备注"
                        
                         :show-overflow-tooltip="true" />
        <el-table-column prop="status"
                         label="状态"
                         :show-overflow-tooltip="true" />
        
        <el-table-column prop="number"
                         label="操作"
                         width="150">
          <template slot-scope="scope">
            <div>
              <div class="handle" v-if="scope.row.status=='待审核'"
                   @click="passCheck(1, scope.row)">
                通过
              </div>
              <div class="handle" v-if="scope.row.status=='待审核'"
                   @click="passCheck(0, scope.row)">
                不通过
              </div>
              <div class="handle"
                   @click="showDialog(scope.row)">
                查看详情
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination background
                     layout="total,prev, pager, next,sizes"
                     :total="total"
                     :page-sizes="[10, 30, 50]"
                     :page-size="limit"
                     :current-page="page"
                     @current-change="currentChange"
                     @size-change="handleSizeChange" />
    </div>
    <DrawerStoreData :storeVisibleDialog="storeVisibleDialog" :cancel='()=>storeVisibleDialog = false' :storeItem='storeItem'/>
  </div>
</template>

<script>

import DrawerStoreData from './componets/DrawerStoreData'

import {updateshopList,updateshopUpdate,getDealerShopSunExport} from '@/api/attractPlatform/storeData'
import { tableHeight } from '@/mixin/tableHeight'


const formInlineData = [
  {
    label: '录入人工号',
    key: 'account'
  },
  {
    label: '录入人姓名',
    key: 'username'
  },
  {
    label: '门店编号',
    key: 'shopNumber'
  }
]
export default {
  mixins: [tableHeight],
  components:{
    DrawerStoreData
  },
  data() {
    return {
      formInline: {}, // 搜索条件
      formInlineData,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1,
      statusValue:'',
    statusOptions:[
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '待审核'
        },
        {
          value: 1,
          label: '审核通过'
        },
        {
          value: 2,
          label: '审核不通过'
        },
        {
          value: 3,
          label: '确认无误'
        }
    ],
    selectDate:'',
    tableList:[],
    storeVisibleDialog:false,
    storeItem:{}
    }
  },
  watch:{
      selectDate(val){
         if(!val) {
             this.formInline.startCreateTime=''
            this.formInline.endCreateTime=''
            return
         }
         this.formInline.startCreateTime=val[0]
         this.formInline.endCreateTime=val[1]
      }
  },
  created() {
    this.getDataList()
  },
  methods: {
    handleExport(){
      getDealerShopSunExport().then(res=>{
        console.log(res)
      })
    },
    showDialog(item){
      this.storeVisibleDialog=true
      this.storeItem=item
    },
      //审核
      passCheck(type,item){
          const text=type?'确定要通过吗？':'确定审核不通过吗？'
          this.$confirm(text, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type:'warning'
        }).then(() => {
            updateshopUpdate({id:item.id,status:type?type:2}).then(res=>{
                this.getDataList()
                this.$message.success('操作成功！')
            })
          
        }).catch(err=>console.log(err))
      },
      statusChange(val){
        this.formInline.status=val
      },
      reset(){
            this.formInline={}
            this.page = 1
            this.getDataList()
      },
    // 导出excel
    async downloadExcel() {
      
    },
    query() {
      this.page = 1
      this.getDataList()
    },
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })
      this.loading = true
      const res = await updateshopList({
        ...this.formInline,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      if (res.code === 0) {
        const arr=['待审核','审核通过','审核不通过','确认无误']
        res.data.map(item=>item.status=arr[item.status])
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';
.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

</style>
