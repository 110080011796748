<template>
  <div class="active">
    <el-form :model="q" :inline="true">
      <el-form-item label="活动名称：">
        <el-input v-model="q.title" />
      </el-form-item>
      <el-form-item label="活动代码：">
        <el-input v-model="q.number" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="query">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
      </el-form-item>

      <el-row>
        <el-form-item>
          <el-button type="primary" @click="add">添加招商活动</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" :disabled="rowId.length==0" @click="edit">编辑招商活动</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" :disabled="rowId.length==0" @click="del">删除招商活动</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-table ref="filterTable" border :data="dataList" style="width: 100%;margin-top:20px"
      :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}" @row-click="rowClick"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="title" label="招商名称" align="center" />
      <el-table-column prop="activityType" label="活动类型" align="center" width="120" />
      <el-table-column prop="status" label="活动状态" align="center" width="120">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status==1" type="primary">新建</el-tag>
            <el-tag v-else-if="scope.row.status==2" type="warning">下发</el-tag>
            <el-tag v-else-if="scope.row.status==3" type="danger">撤回</el-tag>
            <el-tag v-else type="success">完成</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createUserName" label="创建人" align="center" width="150" />
      <el-table-column prop="createTime" label="创建时间" align="center" width="200" />
      <el-table-column label="操作" align="center" width="330">
        <template slot-scope="scope">
          <div class="sort">
            <div @click.stop="detail(scope.row.activityId)">查看详情</div>
            <div @click.stop="changeStatus(scope.row.activityId,2,scope.row.status)">下发</div>
            <div @click.stop="changeStatus(scope.row.activityId,3,scope.row.status)">撤回</div>
            <div @click.stop="changeStatus(scope.row.activityId,4,scope.row.status)">完成</div>
            <div @click.stop="goMessage(scope.row.activityId)">信息收集</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="paginationBar">
      <el-pagination  layout="total,prev, pager, next,sizes,jumper" :total="total" :page-sizes="[10, 30, 50]"
        :page-size="limit" :current-page="page" @current-change="currentChange" @size-change="handleSizeChange" />
    </div>
    <el-drawer :title="type!='detail'?'基本设置':'活动详情'" :visible.sync="drawer" direction="rtl" size="100%;overflow-y:auto">
      <Form :id="id" :type="type" @cancle="cancle " />
    </el-drawer>
  </div>
</template>

<script>
  import {
    getActiveData,
    delActive,
    activeStatus
  } from '../../../api/attractPlatform/active'
  import Form from './components/Form'
  export default {
    components: {
      Form
    },
    data() {
      return {
        q: {},
        dataList: [],
        rowId: [],
        limit: 10,
        page: 1,
        total: 0,
        drawer: false,
        type: '',
        id: ''
      }
    },
    created() {
      this.getDataList()
    },
    methods: {
      // 获取列表
      async getDataList() {
        const res = await getActiveData({
          ...this.q,
          pageSize: this.limit,
          currPage: this.page
        })
        console.log(res)
        const {
          code,
          data,
          totalCount
        } = res
        if (!code) {
          this.dataList = data
          this.total = totalCount
        }
      },
      goMessage(id) {
        this.$router.push({
          path: '/attractPlatform/messageCollect',
          query: {
            id
          }
        })
      },
      add() {
        this.id = ''
        this.drawer = true
        this.type = 'add'
      },
      edit() {
        if (this.rowId.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能修改一条活动'
          })
          return
        }
        const {
          status
        } = this.dataList.find(item => item.activityId == this.rowId[0])
        if (status != 1) {
          this.$message({
            type: 'warning',
            message: '活动已下发/撤回/完成，无法编辑'
          })
          return
        }
        this.drawer = true
        this.id = this.rowId[0]
        this.type = 'edit'
      },
      del() {
        this.$confirm('确定删除此活动?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await delActive(this.rowId)
          if (!res.code) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getDataList()
          }
        })
      },
      query() {
        this.page = 1
        this.getDataList()
      },
      reset() {
        this.q = {}
        this.page = 1
        this.limit = 10
        this.getDataList()
      },
      detail(id) {
        console.log(id)
        this.id = id
        this.type = 'detail'
        this.drawer = true
      },
      changeStatus(activityId, status, curStatus) {
        if (curStatus == 3 || curStatus == 4) {
          const msg = curStatus == 3 ? '撤回' : '完成'
          this.$message.error(`活动已${msg}，无法修改活动状态`)
          return
        }
        this.$confirm('确定修改此活动状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await activeStatus({
            activityId,
            status
          })
          if (!res.code) {
            this.$message({
              type: 'success',
              message: '修改成功'
            })
            this.getDataList()
          }
        })
      },
      cancle(val) {
        if (val) {
          this.getDataList()
        }
        this.type = ''
        this.drawer = false
      },
      handleSelectionChange(val) {
        this.rowId = val.map(item => {
          return item.activityId
        })
      },
      // 点击行实现点击复选框
      rowClick(row, column, event) {
        this.$refs.filterTable.toggleRowSelection(row)
      },
      // 页面切换
      currentChange(page) {
        this.page = page
        this.getDataList()
      },
      // 页数切换
      handleSizeChange(page) {
        this.page = 1
        this.limit = page
        this.getDataList()
      }
    }
  }

</script>

<style lang='scss' scoped>
  @import '@/styles/colors';
  @import '@/styles/mixin';

    .sort {
      color: $primary-color;
      width: 100%;
      display: flex;
      justify-content: space-around;

      div {
        cursor: pointer;
        padding: 0 3px;
      }
    }
  .paginationBar {
    @include flex(center, center);
    width: 100%;
    height: 60px;
    overflow-x: auto;
  }

  @media(max-width: 1200px) {
    ::v-deep .el-pagination {
      width: 100%;
    }
  }

</style>
